import PropTypes from 'prop-types';
import {Typography, Grid, ThemeProvider, CssBaseline} from '@mui/material';

import {ReactComponent as EvadataLogo} from 'src/images/EvadataLogo404.svg';
import {EvadataTheme} from 'src/theme/schemes/EvadataTheme';


function ErrorScreen({graphic, heading, message, buttons}) {
  return (
    <ThemeProvider theme={EvadataTheme}>
      <CssBaseline/>
      <Grid container>
        <Grid item xs={12} sx={{textAlign: 'center', marginTop: '100px'}}>
          <EvadataLogo />
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', marginTop: '40px'}}>
          {graphic}
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', marginTop: '20px'}}>
          <Typography variant="h2" color="#00396F">{heading}</Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', marginTop: '50px'}}>
          <Typography variant="h5" color="black" sx={{maxWidth: '700px', margin: '0 auto'}}>{message}</Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', marginTop: '50px'}}>
          {buttons}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

ErrorScreen.propTypes = {
  graphic: PropTypes.element,
  heading: PropTypes.string,
  message: PropTypes.string,
  buttons: PropTypes.element
};

export default ErrorScreen;
