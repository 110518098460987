import React from 'react';
import {styled, Typography, Box} from '@mui/material';
import PropTypes from 'prop-types';

import LoadError from '../LoadError';


const StyledGridOverlay = styled('div')(() => ({
  paddingLeft: '10px'
}));

function CustomNoRowsOverlay({isApiError, emptyMessage}) {
  if (isApiError) {
    return <Box sx={{pt: 14}}><LoadError /></Box>;
  } else {
    return (
      <StyledGridOverlay>
        <Typography sx={{p: 2}}>{emptyMessage}</Typography>
      </StyledGridOverlay>
    );
  }
}

CustomNoRowsOverlay.propTypes = {
  isApiError: PropTypes.bool,
  emptyMessage: PropTypes.string
};

export default CustomNoRowsOverlay;
