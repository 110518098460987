import React, {useState} from 'react';
import {useOutletContext, useParams} from 'react-router-dom';
import {Paper, Typography} from '@mui/material';
import {useQuery} from 'react-query';
import PropTypes from 'prop-types';

import {lookUpMatches} from 'src/api';
import TableWithPagination from 'src/components/TableWithPagination';
import columns from './columns';
import {SkeletonRow} from './skeletonRow';
import Overlay from './overlay';


function LookUpResults({customerId, matchId}) {
  const {carrierId} = useParams();
  const [sortMatchDateAsc, setSortMatchDateAsc] = useState(true);
  const [searchQueryParams, setSearchQueryParams] = useState({
    limit: 10,
    previous: undefined,
    next: undefined
  });
  const [rowCount, setRowCount] = useState({
    rowStart: 1,
    rowEnd: searchQueryParams.limit
  });

  const getSearchResults = () => {
    const {limit, previous, next} = searchQueryParams;

    if (customerId || matchId) {
      return lookUpMatches(carrierId, customerId, matchId, limit, previous, next, String(sortMatchDateAsc));
    } else {
      return {};
    }
  };

  const lookupResponse = useQuery({
    queryKey: ['lookupResults', carrierId, customerId, matchId, searchQueryParams, sortMatchDateAsc],
    queryFn: getSearchResults
  });

  const setPageSize = (pageSize) => {
    setSearchQueryParams((previousParams) => {
      return {
        ...previousParams,
        limit: pageSize,
        next: '',
        previous: ''
      };
    });

    setRowCount({
      rowStart: 1,
      rowEnd: pageSize
    });
  };

  const nextPage = function() {
    setSearchQueryParams((previousParams) => {
      return {
        limit: previousParams.limit,
        next: lookupResponse.data?.pagination?.next,
        previous: ''
      };
    });

    setRowCount((previousRowCount) => {
      return {
        rowStart: previousRowCount.rowStart + searchQueryParams.limit
      };
    });
  };

  const previousPage = function() {
    setSearchQueryParams((previousParams) => {
      return {
        limit: previousParams.limit,
        next: '',
        previous: lookupResponse.data?.pagination?.previous
      };
    });

    setRowCount((previousRowCount) => {
      return {
        rowStart: previousRowCount.rowStart - searchQueryParams.limit
      };
    });
  };

  const {carrier} = useOutletContext();
  const isVerified = carrier.isVerified;
  const numResults = lookupResponse?.data?.results?.length;

  if (numResults === 0 || (!matchId && !customerId)) {
    return <Overlay displayNoResults={numResults === 0} />;
  }

  return (
    <Paper sx={{mb: 2, mt: 1}}>
      <Typography p={2}>Showing <strong>{numResults}</strong> result{numResults > 1 && 's'} for {customerId ? `Customer ID ${customerId}` : `Match ID ${matchId}`}</Typography>
      <TableWithPagination
        initialState={{
          sorting: {
            sortModel: [{field: 'matchDate', sort: 'asc'}]
          }
        }}
        handleSort={(sortModel) => setSortMatchDateAsc(sortModel[0].sort === 'asc')}
        rows={lookupResponse?.data?.results}
        columns={columns(carrierId)}
        columnVisibilityModel={{
          customerId: Boolean(matchId),
          emId: Boolean(customerId),
          matchDecision: isVerified}
        }
        isDataTableLoading={!lookupResponse.isFetched} // we use isFetched instead of isLoading because isLoading is not available until the query is fired and the query is not fired before rendering this table. This allows us to display the loading state
        rowHeight={100}
        getNextPage={nextPage}
        getPreviousPage={previousPage}
        next={searchQueryParams.next}
        prev={searchQueryParams.previous}
        pageSize={searchQueryParams.limit}
        setPageSize={setPageSize}
        isApiError={lookupResponse.isError}
        SkeletonRow={<SkeletonRow isVerified={isVerified}/>}
        rowStart={rowCount.rowStart}
        rowEnd={rowCount.rowEnd}
      />
    </Paper>
  );
}

LookUpResults.propTypes = {
  customerId: PropTypes.string,
  matchId: PropTypes.string
};

export default LookUpResults;
