import React from 'react';
import PropTypes from 'prop-types';
import {Skeleton, Box, styled} from '@mui/material';

const SkeletonWrapper = styled(Box)(
  () => `
    .skel {
      margin: 32px 18px 32px 10px;
      float: left;
    }
  `
);

const customMatchSkeleton = (width, key) => (
  <Skeleton key={key} variant="rectangular" sx={{width: width}} className="skel" />
);

const widthMapping = {
  new: {
    verified: ['12%', '12%', '11%', '10%', '12.5%', '13%', '12%'],
    standard: ['12%', '12%', '11.5%', '6.5%', '6.5%', '6%', '8%', '4%', '12%']
  },
  updated: {
    verified: ['11%', '7%', '11%', '10%', '9%', '11.5%', '11%', '10%'],
    standard: ['11.5%', '7%', '10%', '10.5%', '5.5%', '5.5%', '6%', '7%', '4%', '12%']
  },
  deleted: {
    verified: ['11.5%', '16%', '11.5%', '6%', '6%', '5.5%', '8%', '4%', '11.5%'],
    standard: ['11.5%', '16%', '11.5%', '6%', '6%', '5.5%', '8%', '4%', '11.5%']
  }
};


export function SkeletonRow({selectedSection, isVerified}) {
  const verifiedMapping = isVerified ? 'verified' : 'standard';
  const widths = widthMapping[selectedSection][verifiedMapping];

  return (
    <SkeletonWrapper>
      <>
        {widths?.map((width, index) => {
          return customMatchSkeleton(width, index);
        })}
      </>
    </SkeletonWrapper>
  );
}

SkeletonRow.propTypes = {
  selectedSection: PropTypes.string,
  isVerified: PropTypes.bool
};
