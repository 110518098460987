import React from 'react';
import {Skeleton, Box} from '@mui/material';


export const SkeletonRow = () => (
  <Box sx={{}}>
    <Skeleton variant="rectangular" sx={{mt: '18px', mb: '22px', ml: 1, mr: 2, width: '38%', float: 'left'}} />
    <Skeleton variant="rectangular" sx={{mt: '18px', mb: '22px', ml: 1, mr: 2, width: '37%', float: 'left'}} />
    <Skeleton variant="rectangular" sx={{mt: '18px', mb: '22px', ml: 1, mr: 2, width: '14%', float: 'left'}} />
  </Box>
);
