import React from 'react';
import {Skeleton, Box} from '@mui/material';

export const SkeletonRow = () => (
  <Box sx={{}}>
    <Skeleton variant="rectangular" sx={{mt: 3, mb: 3, ml: 1, mr: 2, width: '18%', float: 'left'}} />
    <Skeleton variant="rectangular" sx={{mt: 3, mb: 3, ml: 1, mr: 2, width: '17%', float: 'left'}} />
    <Skeleton variant="rectangular" sx={{mt: 3, mb: 3, ml: 1, mr: 2, width: '25%', float: 'left'}} />
    <Skeleton variant="rectangular" sx={{mt: 3, mb: 3, ml: 1, mr: 2, width: '17%', float: 'left'}} />
    <Skeleton variant="rectangular" sx={{mt: 3, mb: 3, ml: 1, mr: 2, width: '9%', float: 'left'}} />
  </Box>
);
