import React from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import ErrorScreen from 'src/components/ErrorScreen';
import EmailSupportButton from 'src/components/EmailSupportButton';
import CommGraphic from 'src/images/500ServerCommGraphic.png';
import RefreshButton from './refreshButton';


function AppError({error}) {
  const location = useLocation();

  const heading = 'Something went wrong.';
  const message = 'There was a problem loading this page. Please try refreshing your browser or contact Evadata if the issue persists.';
  const graphic = <img src={CommGraphic} alt='App error'/>;
  const emailSubject = 'LENS Application error';
  const emailBody = `${error}\n\nOccured At Path: ${location.pathname}`;
  const buttons = <><EmailSupportButton subject={emailSubject} body={emailBody}/><span style={{margin: '10px'}}></span><RefreshButton/></>;

  return (
    <ErrorScreen graphic={graphic} heading={heading} message={message} buttons={buttons}/>
  );
}

AppError.propTypes = {
  error: PropTypes.object
};

export default AppError;
