import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSearchParams} from 'react-router-dom';
import {Switch, Tooltip, Typography} from '@mui/material';


function MatchDecisionToggle({isVerified}) {
  const [searchParams, setSearchParams] = useSearchParams({showMatchDecisionView: isVerified});
  const isToggleChecked = searchParams.get('showMatchDecisionView') === 'true';

  const toggleMatchDecisionView = function() {
    searchParams.set('showMatchDecisionView', !isToggleChecked);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (isToggleChecked) {
      // set the default section in the url query so that parent components are aware
      // Long explanation: The URL does not have the "showMatchDecisionView" query param set
      // We have a default initiation in the "useSearchParams" hook, which sets it based on if the
      // carrier is verified or not.
      // Now this queryParam needs to be available in the url query param so that other components
      // can display the appropriate view in their components.
      // Remember: URL query params are the way we manage state
      // The below call sets "showMatchDecisionView" in url. We need this to run only once when the
      // component renders. After that the user may toggle the value.
      // Also, note that the default initiation in useSearchParams is only take into account if there
      // isn't a value for "showMatchDecisionView" in the query params.
      setSearchParams(searchParams);
    } // eslint-disable-next-line
  }, []); 

  return (
    <>
      <Typography display="inline" variant="subtitle1">Match Decision View</Typography>
      <Tooltip arrow placement="left" title={isToggleChecked ? 'Turn OFF Match Decision View' : 'Turn ON Match Decision View'} >
        <Switch checked={isToggleChecked} onChange={toggleMatchDecisionView} />
      </Tooltip>
    </>
  );
}

MatchDecisionToggle.propTypes = {
  isVerified: PropTypes.bool.isRequired
};

export default MatchDecisionToggle;
