import {useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Backdrop, Box, Button, Drawer, Grid, Tooltip, Typography, styled} from '@mui/material';
import {Alarm, History} from '@mui/icons-material';
import Highlight from '@evadataprotectcode/evadata-react-highlighter';

import {formatDateString} from 'src/utilities/formatDateString';
import {findHighlightRegEx} from 'src/utilities/getRegexToFindCityState';


function RecordEntrySecondary({title, deathRecordData, customerData, link = false, drawer = false, linkText, fieldName, historical, result}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [showOverflow, setShowOverflow] = useState(false);
  const [showHistoricOverflow, setShowHistoricOverflow] = useState(false);

  const highlightRegEx = findHighlightRegEx(customerData, deathRecordData);
  const highlightCss = {
    background: '#FFB4004D'
  };

  const prevDeathRecord = historical?.deathRecord;
  const historicalData = prevDeathRecord ? Object.keys(prevDeathRecord) : undefined;
  const updated = historicalData?.includes(fieldName) && (prevDeathRecord?.[fieldName] !== deathRecordData);

  const LinkWrapper = styled(Link)(
    ({theme}) => `
      color: ${theme.palette.text.primary}
    `
  );

  const updatedTooltipText = 'Updated death record details from ' + result?.matchDate;
  const historicTooltipText = 'Historic death record details from ' + historical?.matchDate;

  const toggleDrawer = (fieldTitle) => {
    setDrawerTitle(fieldTitle);
    setDrawerOpen(!drawerOpen);
  };

  const toggleOverflow = () => {
    setShowOverflow(!showOverflow);
  };

  const toggleHistoricOverflow = () => {
    setShowHistoricOverflow(!showHistoricOverflow);
  };

  function expandableData(field, data, trimmedData, overflowVar, toggleFn) {
    return (
      <Typography display="inline" variant="body1">{field.includes('date') && formatDateString(data)}
        {!field.includes('date') && !overflowVar && trimmedData}
        {!field.includes('date') && overflowVar && data}
        {(data?.length > 40 && !overflowVar) && <Tooltip arrow title="Show More" ><LinkWrapper onClick={toggleFn}>...</LinkWrapper></Tooltip>}
        {(data?.length > 40 && overflowVar) &&
          <>
            <br />
            <Typography sx={{mt: 1, mb: 1}} variant="body2"><Link onClick={toggleFn}>Hide overflow text</Link></Typography>
          </>}
      </Typography>
    );
  }

  return (
    <>
      <Backdrop
        className='calendar-drawer-backdrop'
        sx={{color: '#fff', zIndex: 99, opacity: '0.5 !important'}}
        open={drawerOpen}
        onClick={() => toggleDrawer(title)}
      />
      {deathRecordData &&
        <Box className="record-entry-container">
          <Box className="record-entry-header" sx={{px: 1, display: 'flex', alignContent: 'center'}} >
            <Typography variant="subtitle1">{title}</Typography>
            {updated && <Tooltip title={updatedTooltipText} arrow placement="right"><Alarm sx={{mt: '3px', ml: 1}} color="primary" /></Tooltip>}
          </Box>
          <Box className="record-entry-data">
            <Grid container sx={{minHeight: '40px'}}>
              <Grid item xs={12} sx={{pt: 1}}>
                {drawer &&
                  <Button onClick={() => toggleDrawer(title)} className="link-button" variant="text">
                    <Typography display="inline" variant="body1">{linkText}</Typography>
                  </Button>
                }
                {link &&
                  <Link to={deathRecordData} target="_blank" className="link-button" variant="text">
                    <Typography display="inline" variant="body1">{deathRecordData}</Typography>
                  </Link>
                }
                {(!drawer && !link) &&
                  <>{expandableData(fieldName, deathRecordData, deathRecordData?.slice(0, 40), showOverflow, toggleOverflow)}</>
                }
              </Grid>
            </Grid>
          </Box>
          {updated &&
            <>
              <Box className="record-entry-header historic" sx={{px: 1, display: 'flex', alignContent: 'center'}}>
                <Typography variant="subtitle1">{title}</Typography>
                <Tooltip title={historicTooltipText} arrow placement="right"><History sx={{mt: '3px', ml: 1}} color="primary" /></Tooltip>
              </Box>
              <Box className="record-entry-data">
                <Grid container sx={{minHeight: '40px'}}>
                  <Grid item xs={12} sx={{pt: 1}}>
                    {drawer &&
                      <Button onClick={() => toggleDrawer(title)} className="link-button" variant="text">
                        <Typography display="inline" variant="body1">{linkText}</Typography>
                      </Button>
                    }
                    {link &&
                      <Link to={prevDeathRecord?.[fieldName]} target="_blank" className="link-button" variant="text">
                        <Typography display="inline" variant="body1">{prevDeathRecord?.[fieldName]}</Typography>
                      </Link>
                    }
                    {(!drawer && !link) &&
                      <>{expandableData(fieldName, prevDeathRecord?.[fieldName], prevDeathRecord?.[fieldName]?.slice(0, 40), showHistoricOverflow, toggleHistoricOverflow)}</>
                    }
                  </Grid>
                </Grid>

              </Box>
            </>
          }
        </Box>


      }
      <Drawer
        variant="persistent"
        open={drawerOpen}
        anchor='right'
      >
        <Box sx={{width: '600px'}}>
          <Typography variant="h6" sx={{m: 2, fontWeight: 'bold', pl: 1}}>Additional Details from Death Record</Typography>
          <hr />
          <Box className="obit-details" sx={{m: 3}}>
            <Box className="obit-details-header" sx={{p: 2}}>
              <Typography variant="subtitle1">{drawerTitle}</Typography>
            </Box>
            <Typography variant="body1" sx={{p: 2, whiteSpace: 'pre-wrap'}}>
              <Highlight search={highlightRegEx} matchStyle={highlightCss}>{deathRecordData}</Highlight>
            </Typography>
          </Box>
          <Button onClick={() => setDrawerOpen(false)} size="small" sx={{float: 'right', mr: 3}} variant="contained">Close</Button>
        </Box>
      </Drawer>
    </>
  );
}

RecordEntrySecondary.propTypes = {
  title: PropTypes.string,
  deathRecordData: PropTypes.string,
  link: PropTypes.bool,
  drawer: PropTypes.bool,
  linkText: PropTypes.string,
  fieldName: PropTypes.string,
  historical: PropTypes.object,
  result: PropTypes.object,
  customerData: PropTypes.object
};

export default RecordEntrySecondary;
