import {CompareRounded, RuleFolderRounded} from '@mui/icons-material';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';

import {filterItems} from '../../filterItems';
import MenuSection from '../MenuSection';


function ManageSidebarItems({tiers}) {
  const {carrierId} = useParams();

  const menuItems = [
    {
      name: 'All Match Results',
      icon: CompareRounded,
      link: `/${carrierId}/all-match-results`,
      tiers: ['standard', 'lens-plus', 'lens-verified']
    },
    {
      name: 'Automations',
      icon: RuleFolderRounded,
      link: `/${carrierId}/automations`,
      tiers: ['lens-verified']
    }
  ];

  const filteredItems = menuItems.filter((item) => filterItems(item, tiers));

  const displayItems = [
    {
      heading: 'Manage',
      items: filteredItems
    }
  ];

  return (
    <>
      {displayItems.map((section) => (
        <MenuSection heading={section.heading} items={section.items} key={section.heading}></MenuSection>
      ))}
    </>
  );
}

ManageSidebarItems.propTypes = {
  tiers: PropTypes.array
};

export default ManageSidebarItems;
