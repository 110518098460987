import React from 'react';
import {Box, styled, Button, Grid, Typography} from '@mui/material';


const FooterWrapper = styled(Box)(
  () => `
    margin-left: 10px;
    .footer-container {
      padding: 0 20px 30px 20px;
    }
    @media screen and (max-width: 600px) {
      .footer-container {
          padding: 0 0px 0 0px;
      }
    }
  `
);

function Footer() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <FooterWrapper>
      <Grid className='footer-container' container>
        <Grid item xs={6} sx={{pt: '14px'}}>
          <Typography variant="caption">&copy; {currentYear} Evadata</Typography>
        </Grid>
        <Grid item xs={6} sx={{textAlign: 'right'}}>
          <Button href="https://www.evadata.com/terms-of-use" target="blank" variant="text" color="primary">Terms Of Use</Button>
        </Grid>
      </Grid>
    </FooterWrapper>
  );
}

export default Footer;
