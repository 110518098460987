import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@mui/material';


function SectionText({section}) {
  const descriptionMap = {
    new: 'Customer records that matched for the very first time.',
    updated: 'Customer records with an additional source match or an update to a previous death record match.',
    deleted: 'Customer records that matched previously but the death record provided now has a delete request.'
  };

  return (
    <Typography variant="subtitle1">{descriptionMap[section]}</Typography>
  );
}

SectionText.propTypes = {
  section: PropTypes.string.isRequired
};

export default SectionText;
