/* eslint-disable camelcase */
import {lookupFullStateNames} from 'src/utilities/lookupStateNames';
import get from 'lodash/get';
import join from 'lodash/join';
import includes from 'lodash/includes';

export function findHighlightRegEx(carrierRecord, deathRecord) {
  const city = get(carrierRecord, 'city');
  const state = get(carrierRecord, 'state');
  const customer_record_middle_name = get(carrierRecord, 'middle_name');
  const death_record_middle_name = get(deathRecord, 'middle_name');
  const fullStateName = lookupFullStateNames[state];

  const beforeExpression = '(^| |\\(|;|,)';
  const afterExpression = '(\\.|, | |\\)|;|$)';
  const cityExpression = join([beforeExpression, city, afterExpression], '');
  const stateExpression = join([
    beforeExpression, fullStateName, afterExpression, '|',
    beforeExpression, state, afterExpression
  ], '');
  const cityStateExpression = join([cityExpression, stateExpression], '|');

  if (!city || includes([death_record_middle_name, customer_record_middle_name], city)) {
    return fullStateName ? new RegExp(stateExpression, 'i') : /(?!)/;
  }


  return fullStateName ?
    new RegExp(cityStateExpression, 'i') :
    new RegExp(cityExpression, 'i');
}
