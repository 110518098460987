export function formatSsn(el) {
  let formattedSsn;
  if (el?.length < 9) {
    return el;
  }
  if (el) {
    const numbers = el.replace(/[^0-9X]/g, '');
    if (numbers.length === 9) {
      formattedSsn = numbers.substr(0, 3) + '-' + numbers.substr(3, 2) + '-' + numbers.substr(5, 4);
    }
  }
  return formattedSsn;
}
