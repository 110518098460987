import React, {useState} from 'react';

import {Grid, Typography, Box} from '@mui/material';

import SearchBar from './searchBar';
import LookUpResults from './LookUpResults';


function LookUpId() {
  const [lookUp, setLookUp] = useState({});

  return (
    <>
      <Box mb={4}>
        <Typography variant="h5">Look Up ID</Typography>
      </Box>
      <SearchBar setLookUp={setLookUp} />
      <Grid container spacing={3} >
        <Grid item xs={12}>
          <LookUpResults
            customerId={lookUp?.customerId}
            matchId={lookUp?.matchId}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default LookUpId;
