/* eslint-disable new-cap */
import {OktaAuth} from '@okta/okta-auth-js';
import config from 'src/config/okta';

const internals = {};
const _oktaAuth = new OktaAuth(config.oidc);

internals.baseUrl = process.env.REACT_APP_LENS_API;

export const getCarriers = async function(limit, previous, next) {
  return internals.get('/carriers', {limit, previous, next});
};

export const getCarrierInfo = async function(carrierId) {
  return internals.get(`/carriers/${carrierId}`, {});
};

export const getMatchResults = async function(carrierId, fileStatus, limit, previous, next, status) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return internals.get(`/carriers/${carrierId}/dataloads`, {fileStatus, status, limit, previous, next, timezone});
};

export const getMatchResultsMeta = async function(carrierId) {
  return internals.get(`/carriers/${carrierId}/dataloads/metadata`, {});
};

export const getMatches = async function(carrierId, matchJobId, section, limit, previous, next, matchDecision, updateStatus, sources, categories) {
  return internals.get(`/carriers/${carrierId}/match-jobs/${matchJobId}/${section}`, {limit, previous, next, matchDecision, updateStatus, sources, categories});
};

export const getCustomerRecordDetails = async function(carrierId, matchJobId, section, customerId, updateStatus) {
  return internals.get(`/carriers/${carrierId}/match-jobs/${matchJobId}/${section}/${customerId}`, {updateStatus});
};

export const getMatchJobStatus = async function(carrierId, matchJobId) {
  return internals.get(`/carriers/${carrierId}/match-jobs/${matchJobId}/status`, {});
};

export const updateMatchJobStatus = async function(carrierId, matchJobId, payload) {
  return internals.put(`${internals.baseUrl}/carriers/${carrierId}/match-jobs/${matchJobId}/status`, payload);
};

export const lookUpMatches = async function(carrierId, customerId, emId, limit, previous, next, isScanIndexForward) {
  return internals.get(`/carriers/${carrierId}/matches`, {customerId, emId, limit, previous, next, isScanIndexForward});
};

export const getMatchJobDetails = async function(carrierId, matchJobId) {
  return internals.get(`/carriers/${carrierId}/match-jobs/${matchJobId}`, {});
};

export const getErrorFileSignedUrl = async function(carrierId, dataLoadId) {
  return internals.get(`/carriers/${carrierId}/dataloads/errors/download`, {dataLoadId});
};

export const getAutomations = async function(carrierId, limit, previous, next, dataLoadId) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return internals.get(`/carriers/${carrierId}/automations`, {limit, previous, next, dataLoadId, timezone});
};

export const getAutomationExecutions = async function(carrierId, limit, previous, next, status) {
  return internals.get(`/carriers/${carrierId}/automation-executions`, {limit, previous, next, status});
};

export const getAutomationById = async function(carrierId, automationId) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return internals.get(`/carriers/${carrierId}/automations/${automationId}`, {timezone});
};

export const getAutomationExecutionsById = async function(carrierId, automationId) {
  return internals.get(`/carriers/${carrierId}/automation-executions/${automationId}`, {});
};

export const postAutomationExecution = async function(carrierId, payload) {
  const url = `${internals.baseUrl}/carriers/${carrierId}/automation-executions`;

  return internals.post(url, payload);
};

export const getTransferMatchFile = async function(carrierId, dataLoadId) {
  return internals.get(`/carriers/${carrierId}/dataloads/${dataLoadId}/send-reports`, {});
};

export const transferMatchFile = async function(carrierId, dataLoadId, payload) {
  return internals.put(`${internals.baseUrl}/carriers/${carrierId}/dataloads/${dataLoadId}/send-reports`, payload);
};

export const postMatchDecision = async function(carrierId, matchJobId, section, customerId, emId, payload) {
  const url = `${internals.baseUrl}/carriers/${carrierId}/match-jobs/${matchJobId}/${section}/${customerId}/matches/${emId}`;

  return internals.put(url, payload);
};

export const getMatchFileConfigurations = async function(carrierId) {
  return internals.get(`/carriers/${carrierId}/match-file-configurations`, {});
};

export const setupAutomations = async function(carrierId, payload) {
  const url = `${internals.baseUrl}/carriers/${carrierId}/automations`;

  return internals.post(url, payload);
};

export const editAutomation = async function(carrierId, automationId, payload) {
  const url = `${internals.baseUrl}/carriers/${carrierId}/automations/${automationId}`;

  return internals.put(url, payload);
};

internals.get = async function(route, query) {
  const accessToken = _oktaAuth.getAccessToken();
  const queryParams = new URLSearchParams();

  for (const [key, value] of Object.entries(query)) {
    if (value) {
      if (Array.isArray(value)) {
        for (const arrayValue of value ) {
          queryParams.append(key, arrayValue);
        }
      } else {
        queryParams.append(key, value);
      }
    }
  }

  const queryString = queryParams.toString();

  const url = `${internals.baseUrl}${route}?${queryString}`;
  // Leaving this in place for testing and demos
  // const url = `${internals.baseUrl}/fail-route`;

  return internals.fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

internals.delete = async function(url) {
  const accessToken = _oktaAuth.getAccessToken();

  return internals.fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

internals.post = async function(url, payload) {
  const accessToken = _oktaAuth.getAccessToken();

  return internals.fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

internals.put = async function(url, payload) {
  const accessToken = _oktaAuth.getAccessToken();

  return internals.fetch(url, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

internals.fetch = async function(url, payload) {
  const result = await fetch(url, payload);

  if (result.ok) { // we have success, return the json payload
    if (result.status === 204) {
      return {'status': result.status, 'statusText': result.statusText};
    }
    return result.json();
  }

  const _json = await result.json();

  const {statusCode} = _json; // when the server throws a Joi error, then this is available and we want the body

  if (statusCode > 200) {
    return _json;
  }

  return result;
};
