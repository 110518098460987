import {Box, Button, Typography} from '@mui/material';
import {Link} from 'react-router-dom';

function automationColumns() {
  const columns = [
    {
      field: 'name', headerName: 'Automation Name', sortingOrder: ['desc', 'asc'], flex: 1.4, renderCell: (params) => {
        return (
          <div className="wrap-cell customer-id flex">
            <Typography variant="body2">{params.value}</Typography>
          </div>
        );
      }
    },
    {
      field: 'description', headerName: 'Description', sortingOrder: ['desc', 'asc'], flex: 1.4, renderCell: (params) => { // for deleted matches only.
        return (
          <div className="wrap-cell customer-id flex">
            <Typography variant="body2">{params.value}</Typography>
          </div>
        );
      }
    },
    {
      field: 'configuredFiles', headerName: 'Usage (File Configurations)', sortingOrder: ['desc', 'asc'], flex: 2, renderCell: (params) => {
        const _configs = params?.value;
        return (
          <Box className="wrap-cell customer-id flex">
            <Box sx={{display: 'block !important'}}>
              {_configs?.map((config) => (
                <Box key={params.row.id + '-' + config?.filePrefix} sx={{mb: 1}}>
                  {config?.nickname && <Typography variant="body1">{config?.nickname}</Typography>}
                  {config?.filePrefix && <Typography variant="body2" color="text.secondary">{config?.filePrefix}</Typography>}
                </Box>
              ))}
            </Box>
          </Box>
        );
      }
    },
    {
      field: 'updated', headerName: 'Last Updated Date', sortingOrder: ['desc', 'asc'], flex: 1.4,
      renderCell: (params) => {
        return <div className="wrap-cell flex" ><Typography variant="body2">{params.value}</Typography></div>;
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.8,
      sortable: false,
      sortingOrder: ['desc', 'asc'],
      cellClassName: 'cat-level action-col',
      renderCell: (params) => {
        return (
          <div className="wrap-cell flex" >
            <Typography variant="body2">
              <Button sx={{my: 1}} LinkComponent={Link} to={`${params.row.id}`} variant="outlined" size="small">
                Edit
              </Button>
            </Typography>
          </div>
        );
      }
    }
  ];

  return columns;
}


export default automationColumns;
