export function formatDateString(el) {
  let formattedDob;
  if (el) {
    const numbers = el.replace(/[^0-9]/g, '');
    if (numbers.length === 8) {
      formattedDob = numbers.substr(4, 2) + '-' + numbers.substr(6, 2) + '-' + numbers.substr(0, 4);
    }
  }
  return formattedDob;
}
