import React, {useEffect, useState} from 'react';
import {Grid, styled} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import PropTypes from 'prop-types';

import CustomNoRowsOverlay from './customNoRowsOverlay';
import Pagination from './Pagination';
import SkeletonRows from './skeletonRows';


const GridWrapper = styled(Grid)(
  ({theme}) => `
    .wrap-cell {
      white-space: break-spaces;
      width: 100%;
      display: block;
      overflow-wrap: anywhere;
    }
    .wrap-cell.flex {
      display: flex !important;
      height: 100% !important;
    }
    .wrap-cell.flex > .MuiTypography-root {
      display: flex !important;
      align-items: center;
    }
    .wrap-cell.flex > .MuiBox-root {
      align-self: center;
    }
    .MuiDataGrid-columnSeparator {
      opacity: 0 !important;
    }
    .MuiDataGrid-root {
      background-color: ${theme.palette.background.paper} !important;
    }
    .MuiDataGrid-root .MuiDataGrid-row:hover, .MuiDataGrid-root .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
      background-color: ${theme.palette.background.paper} !important;
    }
    .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
      font-weight: bold;
    }
    .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus {
      outline: none;
    }
    .MuiDataGrid-selectedRowCount {
      visibility: hidden;
    }
    .MuiTablePagination-selectLabel, .MuiTablePagination-select, svg.MuiSvgIcon-root.MuiTablePagination-selectIcon {
      visibility: hidden;
    }
    .page-count {
      font-size: 15px;
      bottom: 15px;
      position: absolute;
      right: 100px;
    }
    .pagination-btn {
      color: ${theme.palette.text.primary};
      padding-top: 0px;
      min-width: 40px;
      padding-left: 0px;
      padding-right: 0px;
    }
    .pagination-btn[disabled] {
      color: ${theme.palette.action.disabled};
    }
    .pagination-btn:hover {
      background-color: transparent;
    }
    .page-size-select-container {
      display: inline-block;
    }
    .page-size-select {
      boxShadow: none;
    }
    .page-size-select .MuiSelect-select {
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      font-size: 13px;
    }
    .page-size-select .MuiMenuItem-root {
      font-size: 15px;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
    .hide-results .MuiDataGrid-footerContainer {
      display: none;
    }
    .hide-results {
      min-height: 120px;
    }
    .top-align {
      padding-top: 10px;
      min-height: 80px;
    }
    .top-align-chip {
      padding-top: 14px;
      min-height: 80px;
    }
    .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
      outline: none;
    }
    .MuiDataGrid-scrollbar {
      display: none !important;
    }
    .MuiDataGrid-virtualScrollerRenderZone {
      overflow: hidden;
    }
    @media screen and (max-width: 599px) {
      .MuiDataGrid-virtualScroller {
        overflow-x: scroll !important;
      }
    }
  `
);

function TableWithPagination({rowStart, rowEnd, getNextPage, getPreviousPage, isDataTableLoading, rows, columns, rowHeight, columnVisibilityModel, pageSize, next, prev, setPageSize, isApiError, SkeletonRow, emptyMessage = 'No lists to display.', handleSort, initialState}) {
  const [tableHeight, setTableHeight] = useState('600px');

  const setNewPageSize = (event) => {
    setPageSize(event.target.value);
  };

  useEffect(() => {
    if (rows?.length > 0) {
      if (isApiError) {
        setTableHeight('423px');
      } else if (rowHeight === 'auto') {
        setTableHeight('auto');
      } else {
        const _tableHeight = rowHeight * rows?.length + 110;
        setTableHeight(_tableHeight);
      }
    }
  }, [rowHeight, rows, isApiError]);

  const getRowHeight = () => {
    if (rowHeight) {
      return rowHeight;
    }

    return 'auto';
  };

  return (
    <GridWrapper container sx={{pr: 0, pb: 0}}>
      <div style={{height: tableHeight, width: '100%'}}>
        <DataGrid
          key="server-pagination-table"
          className={((rows?.length === 0) && !isDataTableLoading) ? 'hide-results' : ''}
          slots={{
            loadingOverlay: SkeletonRows,
            pagination: Pagination,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          slotProps={{
            loadingOverlay: {pageSize, SkeletonRow},
            pagination: {isDataTableLoading, prev, getPrevPage: getPreviousPage, next, getNextPage, pageSize, setNewPageSize, recordSetStart: rowStart, recordSetEnd: rowEnd},
            noRowsOverlay: {isApiError, emptyMessage}
          }}
          getRowId={(row) => row?.id || row?.emId || row?.customerId || row?.carrierId}
          onSortModelChange={handleSort}
          rows={rows} columns={columns}
          getRowHeight={getRowHeight}
          disableColumnMenu={true}
          disableColumnSorting={!handleSort}
          sortingMode={handleSort ? 'server' : 'client'}
          columnVisibilityModel={columnVisibilityModel}
          initialState={initialState}
          loading={isDataTableLoading}
          autoHeight={tableHeight === 'auto'}
        />
      </div>
    </GridWrapper>
  );
}

TableWithPagination.propTypes = {
  isDataTableLoading: PropTypes.bool,
  rows: PropTypes.array,
  columns: PropTypes.array,
  rowHeight: PropTypes.any,
  columnVisibilityModel: PropTypes.object,
  pageSize: PropTypes.number,
  next: PropTypes.string,
  prev: PropTypes.string,
  setPageSize: PropTypes.func,
  isApiError: PropTypes.bool,
  emptyMessage: PropTypes.string,
  handleSort: PropTypes.func,
  initialState: PropTypes.object,
  SkeletonRow: PropTypes.element,
  getNextPage: PropTypes.func,
  getPreviousPage: PropTypes.func,
  rowStart: PropTypes.number,
  rowEnd: PropTypes.number
};

export default TableWithPagination;

