import React from 'react';
import {Grid, styled} from '@mui/material';

import Userbox from '../../AppBar/Userbox';
import BaseAppBar from '../../AppBar';
import EvadataLogo from '../../AppBar/EvadataLogo';


const LogoContainerStyled = styled(Grid)(({theme}) => (`
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%);
  z-index: 1100;
  position: fixed;
  background-color: ${theme.sidebar.background};
  height: 80px;
`));

function EvadataAppBar() {
  return (
    <BaseAppBar>
      <LogoContainerStyled className='app-bar-container' container spacing={2} sx={{pl: 2, pr: 2}}>
        <Grid item xs={8} sm={8} sx={{pt: 3}}>
          <EvadataLogo/>
        </Grid>
        <Grid item xs={4} sm={4} sx={{textAlign: 'right', mt: '6px'}}>
          <Userbox />
        </Grid>
      </LogoContainerStyled>
    </BaseAppBar>
  );
}

export default EvadataAppBar;
