import React from 'react';
import PropTypes from 'prop-types';
import {Chip} from '@mui/material';
import {AccessTime, Check, Close} from '@mui/icons-material';


const icons = {
  accepted: <Check />,
  rejected: <Close />,
  todo: <AccessTime />
};

const colors = {
  accepted: 'success',
  rejected: 'error',
  todo: 'info'
};

const labels = {
  accepted: 'Match',
  rejected: 'No Match',
  todo: 'To Do'
};

const getLabel = function(variant, matchDecision) {
  if (variant === 'lookup') {
    if (matchDecision === 'todo') {
      return 'Needs Review';
    }
    return matchDecision;
  }
  return labels[matchDecision];
};

const getColors = function(variant, matchDecision) {
  if (variant === 'lookup' && matchDecision === 'todo') {
    return 'default';
  }
  return colors[matchDecision];
};

const getIcons = function(variant, matchDecision) {
  if (variant === 'lookup' && matchDecision === 'todo') {
    return;
  }
  return icons[matchDecision];
};


function MatchDecisionChip({matchDecision, variant='default', ...props}) {
  const icon = getIcons(variant, matchDecision);
  const color = getColors(variant, matchDecision);
  const label = getLabel(variant, matchDecision);

  return (
    <Chip variant="outlined" icon={icon} color={color} label={label} {...props}/>
  );
}

MatchDecisionChip.propTypes = {
  matchDecision: PropTypes.string,
  variant: PropTypes.string
};

export default MatchDecisionChip;
