import React from 'react';
import {Grid, styled, Typography, Paper} from '@mui/material';
import {DataGrid, GridColumnHeaders} from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import CustomToolbar from './CustomToolbar';

const GridWrapper = styled(Grid)(
  ({theme}) => `
    .wrap-cell {
      white-space: break-spaces;
      width: 100%;
      display: block;
      overflow-wrap: anywhere;
    }
    .wrap-cell.flex {
      display: flex !important;
      height: 100% !important;
    }
    .wrap-cell.flex > .MuiTypography-root {
      display: flex !important;
      align-items: center;
    }
    .wrap-cell.flex > .MuiBox-root {
      align-self: center;
    }
    .MuiDataGrid-columnSeparator {
      opacity: 0 !important;
    }
    .MuiDataGrid-root {
      background-color: ${theme.palette.background.paper} !important;
    }
    .MuiDataGrid-root .MuiDataGrid-row:hover, .MuiDataGrid-root .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
      background-color: ${theme.palette.background.paper} !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
}
.MuiDataGrid-selectedRowCount {
  visibility: hidden;
}
.MuiTablePagination-selectLabel, .MuiTablePagination-select, svg.MuiSvgIcon-root.MuiTablePagination-selectIcon {
  visibility: hidden;
}
.page-count {
  font-size: 15px;
  bottom: 15px;
  position: absolute;
  right: 100px;
}
.match-categories  {
  .header-column {
    text-align: center;
    border-right: 3px solid ${theme.palette.gray.light};
  }
  .cat-col {
    width: 110px;
  }
  .source-col {
    width: 336px;
  }
  .match-col {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 1;
  }
  .row-1 {
    height: 70px;
    padding-top: 20px;
  }
  .row-2 {
    background-color: ${theme.palette.background.default};
    text-align: left;
  }
  .header {
    display: flex;
    &.lower {
      border-bottom: 3px solid ${theme.palette.gray.dark};
      .row-2-cell {
        padding-left: 5px;
        font-weight: 700;
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }
  }
  .ssn {
    padding-left: 10px;
  }
  .wrap-cell {
    height: 50px;
    padding-top: 12px;
    width: 100%
  }
  .wrap-cell.border {
    border-left: 3px solid ${theme.palette.gray.light};
    padding-left: 10px;
  }
  .MuiDataGrid-row {
    border-bottom: none;
  }
  .MuiDataGrid-row:nth-of-type(even), .MuiDataGrid-row:nth-of-type(even):hover  {
    background-color: ${theme.palette.primary.hover} !important;
  }
  .MuiDataGrid-footerContainer {
    display: none;
  }
}
@media screen and (max-width: 599px) {
  .MuiDataGrid-virtualScroller {
    overflow-x: scroll !important;
  }
}
  `
);

function NoRows() {
  return (
    <Typography sx={{m: 4}}>No lists to display.</Typography>
  );
}

function TableWithActionButton({useAutoRowHeight, isDataTableLoading, skeleton, rows, columns, rowHeight, tableHeight, initialState, message, hideTable, columnVisibilityModel, setColumnVisibilityModel, customPageSize = 10, useHeader, customClass, hideFooterPagination}) {
  const customHeader = () => (
    <>
      <Grid container className="header">
        <Grid item className="header-column cat-col row-1">
          <Typography variant="body1" sx={{fontWeight: 700}}>CATEGORY</Typography>
        </Grid>
        <Grid item className="header-column source-col row-1">
          <Typography variant="body1" sx={{fontWeight: 700}}>SOURCE</Typography>
        </Grid>
        <Grid item className="header-column match-col row-1">
          <Typography variant="body1" sx={{fontWeight: 700}}>MATCH CRITERIA</Typography>
        </Grid>
      </Grid>
      <Grid container className="header lower">
        <Grid item className="header-column cat-col row-2">
          <Grid container>
            <Grid item>
              <Typography variant="body1" sx={{fontWeight: 700}}>&nbsp;</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="header-column source-col row-2">
          <Grid container>
            <Grid item xs>
              <Typography variant="body1" className="row-2-cell">DMF</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" className="row-2-cell">State</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" className="row-2-cell">Other <br />Carriers</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" className="row-2-cell">Obituary</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="header-column match-col row-2">
          <Grid container>
            <Grid item sx={{width: '130px', maxWidth: '130px'}}>
              <Typography variant="body1" className="row-2-cell" sx={{fontWeight: 700}}>SSN</Typography>
            </Grid>
            <Grid item sx={{width: '14.2%'}}>
              <Typography variant="body1" className="row-2-cell">DOB</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" className="row-2-cell">First<br />Name</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" className="row-2-cell">Last<br />Name</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" className="row-2-cell">City</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" className="row-2-cell">State</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  function SkeletonRows() {
    return skeleton;
  }

  const [pageSize, setPageSize] = React.useState(customPageSize);

  return (
    <GridWrapper container sx={{pr: 0, pb: 0}}>
      {hideTable ?
        <Grid item xs={12}>
          <CustomToolbar />
          <Paper sx={{mb: 0, mt: 1}}>
            <Grid sx={{height: 90}}>
              <Typography sx={{p: 3}}>{message}</Typography>
            </Grid>
          </Paper>
        </Grid> :
        <>
          {useAutoRowHeight ?
            <div style={{height: tableHeight, width: '160%'}}>
              <DataGrid
                key='action-button-data-grid'
                getRowId={(row) => row?.customerId || row?.matchId || row.carrierId || row.id}
                disableColumnSorting
                pageSize={pageSize}
                className={customClass}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 100]}
                rows={rows} columns={columns}
                initialState={initialState}
                getEstimatedRowHeight={() => 100}
                getRowHeight={() => 'auto'}
                hideFooterPagination={hideFooterPagination}
                pagination
                disableColumnMenu={true}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                loading={isDataTableLoading}
                slots={{
                  toolbar: useHeader ? customHeader : '',
                  noRowsOverlay: NoRows,
                  loadingOverlay: SkeletonRows,
                  columnHeaders: useHeader ? () => null : GridColumnHeaders
                }}
              />
            </div> :
            <div style={{height: tableHeight, width: '160%'}}>
              <DataGrid
                key='action-button-data-grid'
                disableColumnSorting
                pageSize={pageSize}
                className={customClass}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[customPageSize, 25, 100]}
                rows={rows} columns={columns}
                initialState={initialState}
                hideFooterPagination={hideFooterPagination}
                rowHeight={rowHeight}
                pagination
                disableColumnMenu={true}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                loading={isDataTableLoading}
                slots={{
                  toolbar: useHeader ? customHeader : '',
                  noRowsOverlay: NoRows,
                  loadingOverlay: SkeletonRows,
                  columnHeaders: useHeader ? () => null : GridColumnHeaders
                }}
              />
            </div>
          }
        </>
      }
    </GridWrapper>
  );
}

TableWithActionButton.propTypes = {
  isDataTableLoading: PropTypes.bool,
  skeleton: PropTypes.object,
  rows: PropTypes.array,
  columns: PropTypes.array,
  rowHeight: PropTypes.number,
  tableHeight: PropTypes.any,
  initialState: PropTypes.object,
  message: PropTypes.string,
  hideTable: PropTypes.bool,
  useAutoRowHeight: PropTypes.bool,
  useHeader: PropTypes.bool,
  customClass: PropTypes.string,
  hideFooterPagination: PropTypes.bool,
  columnVisibilityModel: PropTypes.array,
  setColumnVisibilityModel: PropTypes.func,
  customPageSize: PropTypes.number
};

export default TableWithActionButton;
