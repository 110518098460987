import {Box, styled} from '@mui/material';

export const TitleWrapper = styled(Box)(
  () => `
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
    box-shadow: 0px 0px 0px 1px #e0e0e0;
    height: 120px;
    margin: -22px -27px 30px;
    padding: 40px 24px;

    .customer-add-btn {
      text-align: right;
    }

    @media screen and (max-width: 599px) {
      height: auto; 
      width: 100vw;

      .customer-accounts-title {
        text-align: center;
      }
      .customer-add-btn {
        text-align: center;
        .MuiButton-root {
          width: 90vw !important;
        }
      }
      .MuiTypography-root.MuiTypography-h4 {
        font-size: 1.5rem;
      }
    }
  `
);

export const TableWrapper = styled(Box)(
  () => `
      .MuiGrid-root .MuiTablePagination-displayedRows {
        display: block !important;
      }
      .MuiGrid-root .MuiInputBase-root {
        margin-right: 25px! important;
      }
      .MuiDataGrid-root {
        border-radius: 12px !important;
      }
      a:-webkit-any-link {
        text-decoration: none !important;
      }
      @media screen and (max-width: 599px) {
        .MuiDataGrid-cell {
        min-width: 200px !important;
      }
      .MuiDataGrid-columnHeader {
        min-width: 200px !important;
      }
    }
  `
);
