import {FindInPageRounded} from '@mui/icons-material';
import {useParams} from 'react-router-dom';

import MenuSection from '../MenuSection';


function GeneralMenuItems() {
  const {carrierId} = useParams();

  const displayItems = [
    {
      heading: 'general menu',
      items: [
        {
          name: 'Look Up ID',
          icon: FindInPageRounded,
          link: `/${carrierId}/look-up-id`,
          tiers: ['standard', 'lens-plus', 'lens-verified']
        }
      ]
    }
  ];

  return (
    <>
      {displayItems.map((section) => (
        <MenuSection key={section.heading} items={section.items}></MenuSection>
      ))}
    </>
  );
}

export default GeneralMenuItems;
