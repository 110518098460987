
import React from 'react';
import PropTypes from 'prop-types';
import {useSearchParams} from 'react-router-dom';

import {Box, Button} from '@mui/material';

function UpdateButtons({currentAutomationTab}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const setTab = (tab) => {
    searchParams.set('tab', tab);
    setSearchParams(searchParams);
  };

  return (
    <Box sx={{mb: 2}}>
      <Button onClick={() => setTab('all')} className={(currentAutomationTab === 'all' || currentAutomationTab === null) ? 'update-btn active' : 'update-btn'} variant="outlined">All Automations</Button>
      <Button onClick={() => setTab('by-match-file')} className={currentAutomationTab === 'by-match-file' ? 'update-btn active' : 'update-btn'} variant="outlined">Execution Status</Button>
    </Box>
  );
}

UpdateButtons.propTypes = {
  currentAutomationTab: PropTypes.string
};

export default UpdateButtons;
