export function getReviewerName(person) {
  if (person?.type === 'bot') {
    return person?.name;
  }

  const firstName = person?.firstName;
  const lastName = person?.lastName;

  return (firstName && lastName) ? `${firstName} ${lastName}` : null;
}
