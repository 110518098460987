import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Box, styled} from '@mui/material';
import {useQuery} from 'react-query';

import {getAutomationById, getMatchFileConfigurations} from 'src/api';
import ErrorAlert from 'src/components/ErrorAlert';

import Header from '../header';
import AutomationForm from '../automationForm';

const PageWrapper = styled(Box)(
  ({theme}) => `
  .info-box {
    background-color: ${theme.palette.action.focus};
    border: 1px solid ${theme.palette.text.disabled};
  }
  `
);


function EditAutomation() {
  const {carrierId, automationId} = useParams();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [formikErrors, setFormikErrors] = useState(null);

  function getConditions(conditions) {
    return conditions.map((item) => {
      const _match = item?.match?.length > 1 ? 'both' : item?.match[0];
      const _condition = {
        attribute: item?.attribute,
        match: _match
      };
      return _condition;
    });
  }

  function getConfiguredPrefixes(configuredFiles) {
    return configuredFiles?.map((item) => {
      return item?.filePrefix;
    });
  }

  const matchFileConfigurations = useQuery(['matchFileConfigs', carrierId], () => getMatchFileConfigurations(carrierId));
  const automation = useQuery(['automationData', automationId], async () => {
    const {name, description, rule, configuredFiles} = await getAutomationById(carrierId, automationId);

    return {
      name,
      description,
      category: rule?.category,
      conditions: getConditions(rule?.conditions),
      action: rule?.action,
      configuredFiles: getConfiguredPrefixes(configuredFiles)
    };
  });

  return (
    <>
      {<ErrorAlert formik={{errors: formikErrors}} showErrorAlert={showErrorAlert} />}
      <PageWrapper>
        <Header headerText='Edit Automation'/>
        <AutomationForm
          type='edit'
          configsLoading={matchFileConfigurations?.isLoading}
          matchConfigs={matchFileConfigurations?.data}
          automation={automation?.data}
          automationLoading={automation?.isLoading}
          setFormikErrors={setFormikErrors}
          setShowErrorAlert={setShowErrorAlert}
        />
      </PageWrapper>
    </>
  );
}

export default EditAutomation;
