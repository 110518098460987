import React from 'react';
import {Button} from '@mui/material';
import {Link, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';


function CancelButton({children, ...otherProps}) {
  const {carrierId, matchJobId} = useParams();
  const to = `/${carrierId}/all-match-results/${matchJobId}/match-results-file-details`;

  return (
    <Button component={Link} to={to} {...otherProps}>
      {children}
    </Button>
  );
}

CancelButton.propTypes = {
  children: PropTypes.node.isRequired
};

export default CancelButton;
