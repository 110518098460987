import {styled, Box} from '@mui/material';

export const PageWrapper = styled(Box)(
  ({theme}) => `
    .ssn-btn {
      padding: 0;
    }
    .ssn-btn:hover {
      background: transparent;
    }
    .ssn-btn-icon  {
      font-size: 20px;
      color: ${theme.palette.text.disabled};
    }

    .update-btn {
      width: 50%;
      border-radius: 0px;
      border: 0px;
      color: ${theme.palette.text.disabled};
      font-size: 17px;
      border-bottom: 4px solid ${theme.palette.background.default};
      text-transform: none;
    }
    .update-btn:hover {
      border: 0px;
      border-bottom: 4px solid ${theme.palette.action.disabled};
    }

    .update-btn.active {
      border-bottom: 4px solid ${theme.palette.primary.dark};
      color: ${theme.palette.primary.dark};
    }

    .table-error {
      border: 1px solid red
    }

    .alert-close {
      color: ${theme.palette.success.main};
    }
  `
);
