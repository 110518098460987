import React from 'react';
import {useOutletContext} from 'react-router-dom';

import GeneralMenuItems from './GeneralMenu/items';
import ManageSidebarItems from './Manage/items';
import DocumentationSidebarItems from './Documentation/items';


function SidebarMenu() {
  const {carrier} = useOutletContext();

  return (
    <>
      <GeneralMenuItems/>
      <ManageSidebarItems tiers={carrier.tiers} />
      <DocumentationSidebarItems/>
    </>
  );
}

export default SidebarMenu;
