export const statusMap = {
  'processing': 'Processing',
  'skipped': 'Skipped',
  'fileFailed': 'File Failed',
  'pendingReview': 'Pending Review',
  'reviewInProgress': 'Review In Progress',
  'reviewCompleted': 'Review Completed',
  'queuedForDelivery': 'Queued for Delivery',
  'fileDelivered': 'File Delivered',
  'delivered': 'File Delivered',
  'deliveryFailed': 'Delivery Failed',
  'prescan': 'Prescan',
  'all': 'All Statuses'
};
