import React from 'react';

import EvadataAppBar from './EvadataAppBar';
import BaseLayout from '../baseLayout';
import {Outlet} from 'react-router-dom';


function EvadataLayout() {
  return (
    <BaseLayout appBar={<EvadataAppBar/>}>
      <Outlet/>
    </BaseLayout>
  );
}

export default EvadataLayout;
