import React from 'react';
import PropTypes from 'prop-types';


import {Box, Typography, styled} from '@mui/material';
import {SearchOffRounded, SearchRounded} from '@mui/icons-material';

const IconWrapper = styled(Box)(
  ({theme}) => `
    background-color: ${theme.palette.action.focus};
    color: ${theme.palette.action.disabled};
    width: 180px;
    height: 180px;
    margin: 0 auto;
    border-radius: 90px;
    padding-top: 45px;
    margin-bottom: 20px;
    .MuiSvgIcon-root {
      font-size: 90px;
    }
  `
);

function Overlay({displayNoResults}) {
  return (
    <Box sx={{height: '554px', width: '100%', textAlign: 'center', pt: '120px'}}>
      <IconWrapper>{displayNoResults ? <SearchOffRounded /> : <SearchRounded />}</IconWrapper>
      <Typography sx={{mb: 1}} variant="h4" color="primary.dark">{displayNoResults ? 'No results found' : 'Customer ID or Match ID Lookup'}</Typography>
      <Typography variant="body1">{displayNoResults ? 'Check the ID entered and try again' : 'Quickly locate death record matches by entering an ID above.'}</Typography>
    </Box>
  );
}

Overlay.propTypes = {
  displayNoResults: PropTypes.bool
};

export default Overlay;

