import React from 'react';

import {Grid, Typography, Link, List, ListItem} from '@mui/material';
import PropTypes from 'prop-types';

function Definitions(props) {
  return (
    <div className="definition-block">
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {props.heading}
        </Typography>
      </Grid>
      {props.text &&
        <Grid item xs={12} sx={{mt: 2}}>
          <Typography variant="body1" gutterBottom>{props.text} <Link target="blank" href={props.endLink}>{props.endLinkText}</Link> {props.finalText}
          </Typography>

          {props?.list &&
            <List sx={{listStyleType: 'disc', pl: 4, fontSize: '15px'}}>
              {props?.list.map((item) => (
                <ListItem key={item} sx={{display: 'list-item', pb: 0, pt: 0}}>{item}</ListItem>
              ))}
            </List>
          }

          {props?.boldDefinitionsList &&
            <List sx={{listStyleType: 'none', p: 0, ml: 0}}>
              <strong>Supports the following:</strong>
              {props?.boldDefinitionsList?.map((item) => (
                <ListItem key={item?.label} sx={{display: 'list-item', pb: 0, pt: '3px', pl: 0}}><strong>{item?.label}:</strong> {item?.definition}</ListItem>
              ))}
            </List>
          }
        </Grid>
      }
    </div>
  );
}

Definitions.propTypes = {
  text: PropTypes.string,
  endLink: PropTypes.string,
  endLinkText: PropTypes.string,
  finalText: PropTypes.string,
  heading: PropTypes.string,
  list: PropTypes.object,
  boldDefinitionsList: PropTypes.array
};

export default Definitions;
