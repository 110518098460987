import PropTypes from 'prop-types';
import {Chip} from '@mui/material';


const colorMap = {
  processing: 'info',
  pending: 'default',
  failed: 'error',
  completed: 'success'
};

const labelMap = {
  processing: 'Running',
  pending: 'Pending Run',
  failed: 'Run Failed',
  completed: 'Run Completed'
};

function AutomationExecutionChip({status}) {
  const label = labelMap[status];
  const color = colorMap[status];

  return (
    <Chip sx={{textTransform: 'capitalize'}} color={color} variant="outlined" size="small" label={label} />
  );
}

AutomationExecutionChip.propTypes = {
  status: PropTypes.string
};

export default AutomationExecutionChip;
