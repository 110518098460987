import React from 'react';
import PropTypes from 'prop-types';

import {Box, Grid, Paper, Typography, styled} from '@mui/material';
import {RuleFolderRounded, CompareRounded} from '@mui/icons-material';

function EmptyAutomations({title, subtitle, icon}) {
  const IconWrapper = styled(Box)(
    ({theme}) => `
      width: 180px;
      height: 180px;
      border-radius: 90px;
      background-color: gray;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${theme.palette.action.selected};

      svg {
        color: ${theme.palette.action.disabled};
        font-size: 100px;
      }
      `
  );

  return (
    <Paper sx={{pt: 7, pb: 7, px: 2, mb: 2, mt: 0}}>
      <Grid container sx={{textAlign: 'center'}}>
        <Grid item xs={12}>
          <IconWrapper>
            {icon === 'all' && <RuleFolderRounded />}
            {icon === 'by-match-file' && <CompareRounded />}
          </IconWrapper>
          <Typography variant="h4" sx={{mb: 3, mt: 2}}>{title}</Typography>
          <Typography variant="body1">{subtitle}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

EmptyAutomations.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string
};

export default EmptyAutomations;
