import React from 'react';
import PropTypes from 'prop-types';
import {Box, Card, CardContent, Grid, Skeleton, Typography} from '@mui/material';
import {AccessTime, Check, Close, PeopleAltRounded} from '@mui/icons-material';

import LoadError from 'src/components/LoadError';
import DisplayFileDetails from './DisplayFileDetails';
import FileStatusChip from 'src/components/FileStatusChip';


function TopCards({fileName, dataloadId, categories, sources, isVerified, fileStatus, totalMatchRecordsCount, toDoRecordsCount, matchRecordsCount, noMatchRecordsCount, carrierDataError, matchFileDetailsError, matchFileStatusError}) {
  const displayTotalMatchRecordsCount = (totalMatchRecordsCount || totalMatchRecordsCount === 0) ? totalMatchRecordsCount : <Skeleton variant='rectangular' width={154} height={42} />;

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <DisplayFileDetails fileName={fileName} dataloadId={dataloadId} categories={categories} sources={sources} minHeight="220px" apiError={matchFileDetailsError} />
      </Grid>
      <Grid item xs={3}>
        <Card sx={{borderRadius: '7px', mb: 3, minHeight: '220px'}}>
          <CardContent>
            <Typography variant="overline" color="text.primary">
              Total Records
            </Typography>
            <br />
            {matchFileStatusError ? <Box sx={{pt: 2}}><LoadError /></Box> :
              <Box sx={{display: 'flex', alignItems: 'center', justifyItems: 'center', pt: 3}}>
                <Box sx={{width: '60px', height: '60px', borderRadius: '7px', pt: 1, display: 'inline-block', mr: 1, boxShadow: '0px 2px 4px -1px #00000033'}}><PeopleAltRounded sx={{color: 'primary.dark', mx: 'auto', display: 'block', fontSize: '40px'}} /></Box> <Typography variant="h4" display="inline-block" >{displayTotalMatchRecordsCount}</Typography>
              </Box>
            }
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card sx={{borderRadius: '7px', mb: 3, minHeight: '220px'}}>
          <CardContent>
            <Typography sx={{mb: 3}} variant="overline" color="text.primary">
              Match File Status
            </Typography>
            {carrierDataError || matchFileStatusError ? <Box sx={{pt: 2}}><LoadError /></Box> :
              <>
                <Typography variant="body1" sx={{mt: 1, mb: 2}}><FileStatusChip fileStatus={fileStatus} isDataLoading={!fileStatus}/></Typography>
                {isVerified &&
                  <>
                    <Typography sx={{mb: '5px'}} variant="body1" display="flex"><AccessTime color="info" sx={{mr: 1}} /><Typography variant="body1" sx={{fontWeight: 'bold', mr: 1}}>{toDoRecordsCount}</Typography>To Do</Typography>
                    <Typography variant="body1" display="flex"><Check color="success" sx={{mr: 1, mb: '5px'}} /><Typography variant="body1" sx={{fontWeight: 'bold', mr: 1}}>{matchRecordsCount}</Typography>Match</Typography>
                    <Typography variant="body1" display="flex"><Close color="warning" sx={{mr: 1}} /><Typography variant="body1" sx={{fontWeight: 'bold', mr: 1}}>{noMatchRecordsCount}</Typography>No Match</Typography>
                  </>
                }
              </>
            }
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

TopCards.propTypes = {
  fileName: PropTypes.string,
  dataloadId: PropTypes.string,
  categories: PropTypes.array.isRequired,
  sources: PropTypes.array.isRequired,
  isVerified: PropTypes.bool.isRequired,
  fileStatus: PropTypes.string,
  toDoRecordsCount: PropTypes.number,
  matchRecordsCount: PropTypes.number,
  noMatchRecordsCount: PropTypes.number,
  carrierDataError: PropTypes.bool,
  totalMatchRecordsCount: PropTypes.number,
  matchFileDetailsError: PropTypes.bool,
  matchFileStatusError: PropTypes.bool
};

export default TopCards;
