import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Box, styled} from '@mui/material';
import {useQuery} from 'react-query';

import {getMatchFileConfigurations} from 'src/api';
import ErrorAlert from 'src/components/ErrorAlert';

import Header from '../header';
import AutomationForm from '../automationForm';


const PageWrapper = styled(Box)(
  ({theme}) => `
  .info-box {
    background-color: ${theme.palette.action.focus};
    border: 1px solid ${theme.palette.text.disabled};
  }
  `
);

function AddAutomation() {
  const {carrierId} = useParams();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [formikErrors, setFormikErrors] = useState(null);

  const matchFileConfigurations = useQuery(['matchFileConfigs'], () => getMatchFileConfigurations(carrierId));

  return (
    <>
      {<ErrorAlert formik={{errors: formikErrors}} showErrorAlert={showErrorAlert} />}
      <PageWrapper>
        <Header headerText='Add Automation'/>
        <AutomationForm
          type='add'
          configsLoading={matchFileConfigurations?.isLoading}
          matchConfigs={matchFileConfigurations?.data}
          automationLoading={false}
          setShowErrorAlert={setShowErrorAlert}
          setFormikErrors={setFormikErrors}
        />
      </PageWrapper>
    </>
  );
}

export default AddAutomation;
