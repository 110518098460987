import {Typography} from '@mui/material';

import AutomationExecutionChip from './automationExecutionChip';


const cell = (value) => {
  return (
    <div className="wrap-cell customer-id flex">
      <Typography variant="body2">{value}</Typography>
    </div>
  );
};

function automationExecutionColumns() {
  const columns = [
    {
      field: 'id', headerName: 'Execution Id', sortingOrder: ['desc', 'asc'], flex: 1.4, renderCell: (params) => {
        return cell(params?.value);
      }
    },
    {
      field: 'automationNames', headerName: 'Automations Executed', sortingOrder: ['desc', 'asc'], flex: 1.2,
      renderCell: (params) => {
        return <div className="wrap-cell flex" ><Typography variant="body2" my={2}>{params?.value?.join(',\n')}</Typography></div>;
      }
    },
    {
      field: 'fileName', headerName: 'Match Results File', sortingOrder: ['desc', 'asc'], flex: 1.6, renderCell: (params) => {
        return cell(params?.value);
      }
    },
    {
      field: 'dataLoadId', headerName: 'Dataload ID', sortingOrder: ['desc', 'asc'], flex: 1.2, renderCell: (params) => {
        return cell(params?.value);
      }
    },
    {
      field: 'totalRecords', headerName: 'Total Records', sortingOrder: ['desc', 'asc'], flex: 0.8, renderCell: (params) => {
        return cell(params?.value?.toLocaleString());
      }
    },
    {
      field: 'status', headerName: 'Execution Status', sortingOrder: ['desc', 'asc'], flex: 1,
      renderCell: (params) => {
        return (
          <div className="wrap-cell flex">
            <Typography>
              {params?.value ? <AutomationExecutionChip status={params.value}/> : '-'}
            </Typography>
          </div>
        );
      }
    },
    {
      field: 'updated', headerName: 'Date', sortingOrder: ['desc', 'asc'], flex: 1.2,
      renderCell: (params) => {
        return <div className="wrap-cell flex" ><Typography variant="body2">{params?.value || '-'}</Typography></div>;
      }
    }
  ];

  return columns;
}

export default automationExecutionColumns;
