import React from 'react';
import {styled, Box} from '@mui/material';

import EmailSupportButton from 'src/components/EmailSupportButton';


const BottomButtonWrapper = styled(Box)(
  ({theme}) => `
    position: absolute;
    bottom: -100px;
    height: 100px;
    box-shadow: ${theme.sidebar.boxShadow};
    width: 100%;
    text-align: center;
    padding-top: 28px;
    background: ${theme.sidebar.background};
  `
);

function SidebarBottomButton() {
  return (
    <BottomButtonWrapper>
      <div className="bottomButtonWrapper">
        <EmailSupportButton/>
      </div>
    </BottomButtonWrapper>
  );
}

export default SidebarBottomButton;
