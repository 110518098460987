import React from 'react';
import PropTypes from 'prop-types';
import {Skeleton, Box, styled} from '@mui/material';

const SkeletonWrapper = styled(Box)(
  () => `
    .skel {
      margin: 32px 18px 32px 10px;
      float: left;
    }
  `
);

export function SkeletonRow({isVerified}) {
  return (
    <SkeletonWrapper>
      {isVerified &&
        <>
          <Skeleton variant="rectangular" sx={{mt: 4, mb: 5, ml: 1, mr: 2, width: '15.5%', float: 'left'}} />
          <Skeleton variant="rectangular" sx={{mt: 4, mb: 5, ml: 1, mr: 2, width: '11%', float: 'left'}} />
          <Skeleton variant="rectangular" sx={{mt: 4, mb: 5, ml: 1, mr: 2, width: '11%', float: 'left'}} />
          <Skeleton variant="rectangular" sx={{mt: 4, mb: 5, ml: 1, mr: 2, width: '11%', float: 'left'}} />
          <Skeleton variant="rectangular" sx={{mt: 4, mb: 5, ml: 1, mr: 2, width: '11%', float: 'left'}} />
          <Skeleton variant="rectangular" sx={{mt: 4, mb: 5, ml: 1, mr: 2, width: '11%', float: 'left'}} />
          <Skeleton variant="rectangular" sx={{mt: 4, mb: 5, ml: 1, mr: 2, width: '11%', float: 'left'}} />
        </>
      }
      {!isVerified &&
        <>
          <Skeleton variant="rectangular" sx={{mt: 4, mb: 5, ml: 1, mr: 2, width: '18%', float: 'left'}} />
          <Skeleton variant="rectangular" sx={{mt: 4, mb: 5, ml: 1, mr: 2, width: '13%', float: 'left'}} />
          <Skeleton variant="rectangular" sx={{mt: 4, mb: 5, ml: 1, mr: 2, width: '12.5%', float: 'left'}} />
          <Skeleton variant="rectangular" sx={{mt: 4, mb: 5, ml: 1, mr: 2, width: '13%', float: 'left'}} />
          <Skeleton variant="rectangular" sx={{mt: 4, mb: 5, ml: 1, mr: 2, width: '13%', float: 'left'}} />
          <Skeleton variant="rectangular" sx={{mt: 4, mb: 5, ml: 1, mr: 2, width: '11%', float: 'left'}} />
        </>
      }
    </SkeletonWrapper>
  );
}

SkeletonRow.propTypes = {
  isVerified: PropTypes.bool
};
