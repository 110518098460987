import React from 'react';
import {Box, Typography} from '@mui/material';


function FormHeader() {
  return (
    <Box sx={{p: 2, pb: 1}}>
      <Typography variant="h6" sx={{fontWeight: 700}}>Automation Execution Details</Typography>
    </Box>
  );
}

export default FormHeader;
