import React, {useEffect} from 'react';
import {useOktaAuth} from '@okta/okta-react';

import InitialLoader from 'src/components/InitialLoader';


function Logout() {
  const {oktaAuth} = useOktaAuth();

  useEffect(() => {
    const logout = async function() {
      return oktaAuth.signOut();
    };

    logout();
  });

  return <InitialLoader/>;
}

Logout.propTypes = {};

export default Logout;
