import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useQuery} from 'react-query';
import {useParams, useSearchParams} from 'react-router-dom';

import {getAutomationExecutions} from 'src/api';

import AutomationsTable from './automationsTable';
import ActionBar from './actionBar';
import EmptyAutomations from './emptyAutomations';
import {SkeletonRow} from './skeletonRow';
import automationExecutionColumns from './automationExecutionColumns';
import ActionBarTools from './actionBarTools';


function DisplayExecuteAutomationsTab({setDisableHeaderButtons}) {
  const {carrierId} = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [params, setParams] = useState({
    limit: 10,
    next: '',
    previous: ''
  });

  const {data, isLoading, isError, refetch, isRefetching} = useQuery(
    ['automationExecutions', params, searchParams.get('filter')],
    () => getAutomationExecutions(carrierId, params.limit, params.previous, params.next, (searchParams.get('filter') || '')),
    {refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: Infinity}
  );

  const handleFilterChange = (event) => {
    if (event.target.value === 'all') {
      searchParams.delete('filter');
    } else {
      searchParams.set('filter', event.target.value);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setDisableHeaderButtons(isError || isLoading);
  }, [isLoading, isError, setDisableHeaderButtons]);

  const isEmpty = !isLoading && data?.results?.length < 1 && !isError;
  const isDisabled = isRefetching || isEmpty;

  return (
    <>
      <ActionBar description={'Status overview for automation executions tied to match results files.'} >
        <ActionBarTools isLoading={isRefetching} isDisabled={isDisabled} refresh={refetch} handleFilterChange={handleFilterChange} value={searchParams.get('filter') || 'all'}/>
      </ActionBar>
      {isEmpty ?
        <EmptyAutomations
          title='Awaiting match files to execute automations.'
          subtitle='When Match Results files move to under review in Periscope, they will display here, initiating the automation process.'
          icon={'by-match-file'}
        /> :
        <AutomationsTable
          rows={data?.results}
          columns={automationExecutionColumns()}
          isDataTableLoading={isLoading}
          rowHeight='auto'
          next={data?.pagination?.next}
          prev={data?.pagination?.previous}
          pageSize={params?.limit}
          isApiError={isError}
          SkeletonRow={<SkeletonRow/>}
          setParams={setParams}
          emptyMessage='No executions with the selected status to display.'
        />
      }
    </>
  );
}

DisplayExecuteAutomationsTab.propTypes = {
  setDisableHeaderButtons: PropTypes.func.isRequired
};

export default DisplayExecuteAutomationsTab;
