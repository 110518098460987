
import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {Button, ButtonGroup, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Skeleton, Typography} from '@mui/material';

import {statusMap} from 'src/utilities/statusMap';
import {getMatchResultsMeta} from 'src/api';


function ActionBar({matchResultsGroup, selectMatchResultsGroup, statusFilter, handleFilterChange, verified}) {
  const {carrierId} = useParams();
  const {isFetching, data} = useQuery(['getMatchResultsMeta', carrierId], () => getMatchResultsMeta(carrierId), {initialData: {}});

  const {processing=0, prescan=0, underReview=0, queuedForDelivery=0, delivered=0} = data;

  const countSkeleton = <Skeleton variant='rectangular' width={25} height={22} sx={{mx: '4px'}} />;

  const groupMap = [
    {
      group: 'processing',
      label: 'PROCESSING',
      count: processing,
      display: true
    },
    {
      group: 'prescan',
      label: 'PRESCANS',
      count: prescan,
      display: true
    },
    {
      group: 'underReview',
      label: 'UNDER REVIEW',
      count: underReview,
      display: verified
    },
    {
      group: 'queuedForDelivery',
      label: 'QUEUED FOR DELIVERY',
      count: queuedForDelivery,
      display: true
    },
    {
      group: 'delivered',
      label: 'DELIVERED',
      count: delivered,
      display: true
    }
  ];

  const filtersByMatchGroup = {
    processing: ['all', 'processing', 'skipped', 'fileFailed'],
    underReview: ['all', 'pendingReview', 'reviewInProgress', 'reviewCompleted'],
    delivered: ['all', 'fileDelivered', 'deliveryFailed']
  };

  const statusFilterMenuItems = filtersByMatchGroup[matchResultsGroup];

  const subtitleText = function(verified) {
    return {
      processing: verified ? 'Match files currently processing. When processing is finished, the files will advance to under review.' : 'Match files currently processing. When processing is finished, the files will advance to queued for delivery.',
      prescan: verified ? 'Match files from prescans that were conducted by Evadata. These files are for internal review only and do not move to Under Review.' : 'Match files from prescans that were conducted by Evadata. These files are for internal review only and do not move to Queued for Delivery.',
      underReview: 'Manage death record matches for customer records in each match files shown.',
      queuedForDelivery: 'Match files queued for delivery to the customer\'s transfer location. Files will advance to delivered on the scheduled delivery date.',
      delivered: 'Match files delivered to the customer\'s transfer location.'
    };
  };

  return (
    <Paper sx={{py: 2, px: 2, mb: 2}}>
      <Grid container>
        <Grid item xs={10}>
          <ButtonGroup sx={{mb: 4}}>
            {
              groupMap.map((val) => {
                return val.display &&
                  <Button
                    key={val.group}
                    sx={{textTransform: 'none', fontWeight: 400}}
                    onClick={() => selectMatchResultsGroup(val.group)}
                    variant={matchResultsGroup === val.group ? 'contained' : 'outlined'}
                  >
                    <strong>{val.label}</strong>&nbsp;({isFetching ? countSkeleton : val.count})
                  </Button>;
              })
            }
          </ButtonGroup>
        </Grid>
        <Grid item xs={2} sx={{textAlign: 'right'}}>
          {matchResultsGroup !== 'queuedForDelivery' && matchResultsGroup !== 'prescan' &&
            <FormControl>
              <InputLabel id="select-helper-label">Filter by Status</InputLabel>
              <Select
                labelId="select-helper-label"
                id="select-helper"
                label="Filter by Status"
                sx={{minWidth: '160px', mr: 2, textAlign: 'left'}}
                MenuProps={{disableScrollLock: true}}
                value={statusFilter}
                onChange={handleFilterChange}
              >
                {statusFilterMenuItems?.map((item) => (
                  <MenuItem key={item} value={item}>{statusMap[item]}</MenuItem>
                ))
                }
              </Select>
            </FormControl>

          }
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' sx={{mb: 2}}>{subtitleText(verified)[matchResultsGroup]}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

ActionBar.propTypes = {
  matchResultsGroup: PropTypes.string.isRequired,
  selectMatchResultsGroup: PropTypes.func.isRequired,
  statusFilter: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  verified: PropTypes.bool.isRequired
};

export default ActionBar;
