import React, {forwardRef} from 'react';
import {Tooltip} from '@mui/material';
import PropTypes from 'prop-types';


const TooltipSpan = forwardRef(function TooltipSpan(props, ref) {
  const {children, title: _title, ...otherProps} = props;

  return (
    <span ref={ref} {...otherProps}>
      {children}
    </span>
  );
});

TooltipSpan.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

function TooltipText(props) {
  const {children, ...otherProps} = props;

  return (
    <Tooltip {...otherProps}>
      <TooltipSpan>
        {children}
      </TooltipSpan>
    </Tooltip>
  );
}

TooltipText.propTypes = {
  children: PropTypes.node
};

export default TooltipText;
