import {FormatListNumberedRounded} from '@mui/icons-material';
import {useParams} from 'react-router-dom';

import MenuSection from '../MenuSection';


function DocumentationSidebarItems() {
  const {carrierId} = useParams();

  const displayItems = [
    {
      heading: 'Documentation',
      items: [
        {
          name: 'Evadata\'s Match Categories',
          icon: FormatListNumberedRounded,
          link: `/${carrierId}/evadatas-match-categories`,
          tiers: ['standard', 'lens-plus', 'lens-verified']
        }
      ]
    }
  ];

  return (
    <>
      {displayItems.map((section) => (
        <MenuSection heading={section.heading} items={section.items} key={section.heading}></MenuSection>
      ))}
    </>
  );
}

export default DocumentationSidebarItems;
