/* eslint-disable react-hooks/rules-of-hooks */
import {Button, Typography} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';


function getColumns() {
  const navigate = useNavigate();

  const navigateToMatches = (id) => {
    navigate(`${id}/all-match-results`);
  };

  const statusMap = {
    'prospect': 'Prospect',
    'on-boarding': 'Onboarding',
    'live': 'Live'
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Company Name',
      flex: 2,
      sortable: false,
      sortingOrder: ['desc', 'asc'],
      cellClassName: 'cat-level',
      renderCell: (params) => {
        return (
          <div className="wrap-cell company-name flex">
            {params.row.status !== 'prospect' ?
              <Typography fontSize="17px" onClick={() => navigateToMatches(params.id)}><Link>{params.value}</Link></Typography> :
              <Typography fontSize="17px">{params.value}</Typography>
            }
          </div>
        );
      }
    },
    {
      field: 'tiers',
      headerName: 'Account Tier',
      flex: 2,
      sortable: false,
      sortingOrder: ['desc', 'asc'],
      cellClassName: 'cat-level',
      renderCell: (params) => {
        const returnDisplayTiers = (value) => {
          if (!value || value?.length === 0) {
            return 'Standard';
          }
          if (value?.includes('lens-plus') && value?.includes('lens-verified')) {
            return 'Plus + Verified';
          }
          if (value?.includes('lens-plus')) {
            return 'Plus';
          }
          if (value?.includes('lens-verified')) {
            return 'Verified';
          }
        };

        return (
          <div className="wrap-cell company-name flex">
            <Typography color="text.primary" variant="subtitle2">{returnDisplayTiers(params.value)}</Typography>
          </div>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Stage in LENS',
      flex: 2,
      sortable: false,
      sortingOrder: ['desc', 'asc'],
      cellClassName: 'cat-level',
      renderCell: (params) => {
        return (
          <div className="wrap-cell company-name flex">
            <Typography>{statusMap[params.value]}</Typography>
          </div>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      sortingOrder: ['desc', 'asc'],
      cellClassName: 'cat-level',
      renderCell: (params) => {
        return (
          <Button variant="outlined" size="small" onClick={() => navigateToMatches(params.id)} sx={{minWidth: '30px'}} disabled={params.row.status === 'prospect'}>
            View Account
          </Button>
        );
      }
    }
  ];

  return columns;
}

export default getColumns;
