import PropTypes from 'prop-types';
import {sortBy} from 'lodash';
import {Box, Card, CardContent, Skeleton, Typography, Chip} from '@mui/material';

import DisplayConfiguredSources from 'src/components/DisplayConfiguredSources';
import LoadError from 'src/components/LoadError';
import {sourceDisplayMap} from 'src/utilities/mapSourceDisplay';


function SourcesCategoriesCard({matches, configCategories, configSources, isError}) {
  const _categories = new Set();
  const _sources = new Set();

  matches?.forEach((item) => {
    _categories.add(parseFloat(item.category));
    _sources.add(item?.source);
  });

  const matchCategories = sortBy(Array.from(_categories));
  const matchSources = Array.from(_sources).sort();

  return (
    <Card sx={{borderRadius: '7px', mb: 3, minHeight: '260px'}}>
      <CardContent>
        <Typography variant="overline" color="text.primary">
          Source & Category matches from death record
        </Typography>
        {isError ? <Box sx={{p: 3}}><LoadError /></Box> :
          <>
            <Typography variant='subtitle1'>Source Matches: <Typography variant="body1" display="inline" > {matchSources?.length > 0 ? matchSources.map((source, index) => {
              return (
                <span key={source}>
                  {sourceDisplayMap[source]}{index !== (matchSources?.length - 1) && ', '}
                </span>
              );
            }) : <Skeleton sx={{display: 'inline-block'}} width={320} height={20} />}</Typography></Typography>
            <Typography variant='subtitle1'>Matched Categories: <Typography variant="body1" display="inline" >{matchCategories && matchCategories?.length > 0 ? matchCategories?.map((cat) => (
              <Chip key={cat} sx={{mr: '4px'}} label={cat} />
            )) : <Skeleton sx={{display: 'block', mb: '-10px'}} width={70} height={50} />}</Typography></Typography>  <DisplayConfiguredSources sources={configSources} categories={configCategories} />
            <br />
          </>
        }
      </CardContent>
    </Card>
  );
}

SourcesCategoriesCard.propTypes = {
  matches: PropTypes.array,
  configCategories: PropTypes.array,
  configSources: PropTypes.array,
  isError: PropTypes.bool
};

export default SourcesCategoriesCard;
