import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Paper, Typography} from '@mui/material';


function ActionBar({description, children}) {
  return (
    <Paper sx={{mb: 2, mt: 4, minHeight: '88px'}}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item sx={{py: 3, px: 2}}>
          <Typography variant="subtitle1">{description}</Typography>
        </Grid>
        {children}
      </Grid>
    </Paper>
  );
}

ActionBar.propTypes = {
  description: PropTypes.string,
  children: PropTypes.element
};

export default ActionBar;
