import React, {useState} from 'react';
import * as yup from 'yup';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Card, Paper, styled} from '@mui/material';
import {FormikProvider, useFormik} from 'formik';
import {useQuery} from 'react-query';

import {getMatchResults, getAutomations, postAutomationExecution} from 'src/api';
import LoadingIcon from 'src/components/Loading/loadingIcon';

import ApiError from './apiError';
import DisplayMatchFiles from './displayMatchFiles';
import Header from './header';
import FormHeader from './formHeader';
import FormFooter from './formFooter';

const PageWrapper = styled(Box)(
  ({theme}) => `
  .info-box {
    background-color: ${theme.palette.action.focus};
    border: 1px solid ${theme.palette.text.disabled};
  }
  `
);

function Loading() {
  return (
    <LoadingIcon contained={true} height="337px" marginTop="200px" />
  );
}

function RunAutomation() {
  const navigate = useNavigate();
  const {carrierId} = useParams();
  const [apiError, setApiError] = useState(false);

  const validationSchema = yup.object().shape({
    matchFile: yup
      .string()
      .required('Selection is required.'),
    automations: yup
      .array()
      .of(yup.string())
      .min(1)
  });

  const formik = useFormik({
    initialValues: {
      matchFile: null,
      automations: []
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        dataLoadId: values.matchFile,
        automationIds: values.automations
      };

      const results = await postAutomationExecution(carrierId, payload);

      if (results?.id) {
        navigate(`/${carrierId}/automations`);
      }

      if (results?.error) {
        setApiError(true);
      }
    }
  });

  const {data, isLoading} = useQuery(['automations', carrierId, formik.values.matchFile], () => getAutomations(carrierId, null, null, null, formik.values.matchFile), {staleTime: Infinity, enabled: Boolean(formik.values.matchFile)});

  const {data: matchResultsPendingData, isLoading: matchResultsPendingLoading} = useQuery(['matchResults', carrierId], () => getMatchResults(carrierId, 'underReview', null, null, null, 'pendingReview'));
  const {data: matchResultsProgessData, isLoading: matchResultsProgressLoading} = useQuery(['matchResults', carrierId], () => getMatchResults(carrierId, 'underReview', null, null, null, 'reviewInProgress'));

  const pendingResults = get(matchResultsPendingData, 'results', []);
  const progressResults = get(matchResultsProgessData, 'results', []);
  const results = uniq(flatten(pendingResults.concat(progressResults)));

  return (
    <PageWrapper>
      <Header/>
      <Paper>
        <FormikProvider enableReinitialize={true} value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Card>
              <FormHeader/>
              <hr />
              {apiError && <ApiError setApiError={setApiError}/>}
              {(matchResultsPendingLoading && matchResultsProgressLoading) || formik.isSubmitting ?
                <Loading /> :
                <DisplayMatchFiles
                  touched={formik?.touched}
                  setFieldTouched={formik?.setFieldTouched}
                  handleChange={formik?.handleChange}
                  handleBlur={formik?.handleBlur}
                  values={formik?.values}
                  errors={formik?.errors}
                  tableLoading={isLoading || formik.isSubmitting}
                  availableAutomations={data?.results}
                  matchFiles={results}/>}
              <hr />
              <FormFooter matchFiles={results} isLoading={isLoading}/>
            </Card>
          </form>
        </FormikProvider>
      </Paper>
    </PageWrapper>
  );
}


export default RunAutomation;
