import React from 'react';
import PropTypes from 'prop-types';
import {styled, Box, Typography} from '@mui/material';

import {ReactComponent as EvadataLogoImage} from 'src/images/EvadataLogo.svg';
import TooltipText from './tooltipText';


const LogoAreaWrapper = styled(Box)(
  ({theme}) => `
    .logo {
        display: block;
        float: left;
        margin-top: 15px;
    }
    .logo svg {
        filter: invert(${theme.colors.logo.inverse});
    }
    .lens-text {
        text-align: left;
        font-weight: bold; 
        color: ${theme.colors.logo.productText}; 
        display: inline-block;
        margin-left: 7px;
        margin-top: 15px;
    }
  `
);

function LogoWithTooltip() {
  return (
    <TooltipText title="Periscope Home" placement="right" arrow>
      <Typography className="logo">
        <EvadataLogoImage/>
      </Typography>
      <Typography variant="h6" className="lens-text">PERISCOPE</Typography>
    </TooltipText>
  );
}

function EvadataLogo({...props}) {
  return (
    <LogoAreaWrapper {...props}>
      <LogoWithTooltip/>
    </LogoAreaWrapper>
  );
}

EvadataLogo.propTypes = {
  linkTo: PropTypes.string
};

export default EvadataLogo;
