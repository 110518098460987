import React, {useLayoutEffect} from 'react';
import PropTypes from 'prop-types';

import {Box, Button, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Select, styled} from '@mui/material';
import {Close, ErrorOutline} from '@mui/icons-material';
import {FormikProvider, useFormik} from 'formik';
import {useSearchParams} from 'react-router-dom';

import * as Yup from 'yup';

const IdSelectWrapper = styled(Select)(
  ({theme}) => `
    width: 180px;
    display: inline-block;
    .MuiInputBase-root legend span {
      margin-top: -8px;
      display: block;
      opacity: 1;
      color: ${theme.palette.action.disabled};
    }
  `
);

const EnterIdWrapper = styled(Box)(
  () => `
    .MuiInputBase-root legend span {
      margin-top: -7px;
      display: block;
      opacity: 1;
    }
  `
);

const searchBarSchema = Yup.object().shape({
  enterId: Yup.string().when('typeOfId', (typeOfId) => {
    return typeOfId[0] === 'matchId' ?
      Yup.string()
        .min(36, 'ID entered is invalid. It must contain 36 characters.')
        .uuid('ID entered is invalid. It must contain only numbers, lowercase letters, and hyphens – and can’t contain spaces or any other characters.')
        .required('') :
      Yup.string().required('');
  })
});

function SearchBar({setLookUp}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const {searchType, searchTerm} = function() {
    const customerId = searchParams.get('customerId');

    if (customerId) {
      return {
        searchType: 'customerId',
        searchTerm: customerId
      };
    }

    const matchId = searchParams.get('matchId');

    if (matchId) {
      return {
        searchType: 'matchId',
        searchTerm: matchId
      };
    }

    return {
      searchType: 'customerId'
    };
  }();

  const formik = useFormik({
    initialValues: {
      typeOfId: searchType,
      enterId: searchTerm
    },
    validationSchema: searchBarSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setSearchParams({
        [values.typeOfId]: values.enterId
      });
    }
  });

  const clearInput = () => {
    formik?.setFieldValue('enterId', '');
    formik?.setFieldTouched('enterId', false);

    setLookUp({});
  };

  // we want to setLookUp before render() happens (useLayoutEffect is fired befroe the browser repaints the screen).
  // see: https://react.dev/reference/react/useLayoutEffect
  // This allows us to show the correct state in the peer component (table)
  useLayoutEffect(() => {
    setLookUp({[searchType]: searchTerm});
  }, [searchType, searchTerm, setLookUp]);

  return (
    <Paper sx={{py: 2, px: 2, mb: 2}}>
      <FormikProvider enableReinitialize={true} value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container sx={{display: 'flex', alignContent: 'center'}}>
            <Grid item xs={12}>
              <FormControl sx={{display: 'inline-block', mr: 1, mb: -1}}>
                <InputLabel id="lookup-select-helper-label">Look Up</InputLabel>
                <IdSelectWrapper
                  fullWidth
                  sx={{minWidth: '170px', width: '100%'}}
                  MenuProps={{disableScrollLock: true}}
                  value={formik.values.typeOfId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id='typeOfId'
                  name='typeOfId'
                  label='Look Up'
                >
                  <MenuItem value="customerId">Customer ID</MenuItem>
                  <MenuItem value="matchId">Match ID</MenuItem>
                </IdSelectWrapper >
              </FormControl>
              <Box sx={{display: 'inline-block', width: 'calc(100% - 350px)'}}>
                <EnterIdWrapper>
                  <FormControl sx={{width: '100%'}}>
                    <OutlinedInput
                      fullWidth
                      id="enterId"
                      name="enterId"
                      label="Enter ID"
                      placeholder="Enter ID"
                      value={formik.values.enterId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(formik?.touched?.enterId) && Boolean(formik?.errors?.enterId)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={clearInput}
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <Close />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {formik?.touched?.enterId && formik?.errors?.enterId &&
                      <FormHelperText error><ErrorOutline sx={{display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px'}} />{formik.errors.enterId}</FormHelperText>
                    }
                  </FormControl>
                </EnterIdWrapper>
              </Box>
              <Box sx={{width: '170px', textAlign: 'right', display: 'inline-block'}}>
                <FormControl>
                  <Button type="submit" sx={{mt: '7px', width: '160px'}} variant="contained" color="primary">View Results</Button>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Paper>
  );
}

SearchBar.propTypes = {
  setLookUp: PropTypes.func
};

export default SearchBar;
