import PropTypes from 'prop-types';
import {Box} from '@mui/material';

import LoadError from 'src/components/LoadError';
import AccordionWrapper from './styles';
import AccordionLoader from './accordionLoader';
import AllMatchAccordions from './allMatchAccordions';
import {memo} from 'react';


const MatchAccordionSection = memo(function MatchAccordionSection({matches, customerRecord, refetchData, isVerified, fileStatus, isLoading, isError}) {
  return (
    <AccordionWrapper>
      {isLoading && <AccordionLoader />}
      {isError && <Box sx={{p: 3}}><LoadError /></Box>}
      <AllMatchAccordions matches={matches} customerRecord={customerRecord} refetchData={refetchData} isVerified={isVerified} fileStatus={fileStatus} />
    </AccordionWrapper>
  );
});

MatchAccordionSection.propTypes = {
  matches: PropTypes.array,
  customerRecord: PropTypes.object,
  refetchData: PropTypes.func,
  isVerified: PropTypes.bool,
  fileStatus: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool
};

export default MatchAccordionSection;
