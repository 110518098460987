import React from 'react';
import PropTypes from 'prop-types';
import times from 'lodash/times';


function SkeletonRows({pageSize, SkeletonRow}) {
  return (
    <>
      {times(pageSize, (index) => <React.Fragment key={index}>{SkeletonRow}</React.Fragment>)}
    </>
  );
}

SkeletonRows.propTypes = {
  pageSize: PropTypes.number,
  SkeletonRow: PropTypes.element
};

export default SkeletonRows;
