
import PropTypes from 'prop-types';
import {Box, Button, Skeleton, Typography} from '@mui/material';


function UpdateButtons({updateType, updateTypes, handleChangeUpdateType}) {
  const buttons = updateTypes?.length === 1 ?
    <Typography variant="h6" sx={{mb: 3, fontWeight: 700}}>Updated Matches <Typography display="inline" sx={{textTransform: 'uppercase', fontWeight: 700}} variant="h6">({updateTypes[0] === 'new' ? 'NEW' : 'UPDATE'})</Typography></Typography> :
    <Box sx={{mb: 3, mt: '-8px'}}>
      <Button onClick={handleChangeUpdateType} className={`update-btn ${(!updateType || updateType === 'new') && 'active'}`} variant="outlined" id="new">Updated Matches (NEW)</Button>
      <Button onClick={handleChangeUpdateType} className={`update-btn ${updateType === 'update' && 'active'}`} variant="outlined" id="update">Updated Matches (UPDATE)</Button>
    </Box>;

  return (
    <>
      {!updateTypes || updateTypes?.length === 0 ? <Skeleton height={50} /> : buttons}
    </>
  );
}

UpdateButtons.propTypes = {
  updateType: PropTypes.string,
  updateTypes: PropTypes.array,
  handleChangeUpdateType: PropTypes.func
};

export default UpdateButtons;
