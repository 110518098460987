import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography, Button, styled, Box} from '@mui/material';
import {ExpandLess, ExpandMore} from '@mui/icons-material';

import {sourceDisplayMap} from 'src/utilities/mapSourceDisplay';
import DisplayGroupedCategories from './DisplayGroupedCategories';


const DisplayCategoriesWrapper = styled(Box)(
  () => `
      .configured-sources-toggle-btn:hover {
        background: transparent;
      }
    `
);

function DisplayConfiguredSources({sources, categories}) {
  const [showConfiguredSources, setShowConfiguredSources] = useState(false);
  const sourcesString = sources?.map((source) => sourceDisplayMap[source]).join(', ');

  const toggleConfiguredSources = () => {
    setShowConfiguredSources(!showConfiguredSources);
  };

  return (
    sources?.length > 0 && categories?.length > 0 && <DisplayCategoriesWrapper>
      <Button className="configured-sources-toggle-btn" variant="text" size="medium" sx={{mt: 2, pl: 0}} onClick={toggleConfiguredSources}> {showConfiguredSources ? 'Hide' : 'Show'} Configured Sources & match categories{showConfiguredSources ? <ExpandLess /> : <ExpandMore />}</Button>
      {showConfiguredSources &&
        <Box sx={{textAlign: 'left'}}>
          <Typography variant="subtitle1">Sources Configured by Customer:</Typography>
          <Typography variant="body1">{sourcesString}</Typography>
          <Typography variant="subtitle1" sx={{mt: 2}}>Match Categories Configured by Customer:</Typography>
          <Grid container spacing={3}><DisplayGroupedCategories categories={categories} /></Grid>
        </Box>
      }
    </DisplayCategoriesWrapper>
  );
}

DisplayConfiguredSources.propTypes = {
  categories: PropTypes.array,
  sources: PropTypes.array
};

export default DisplayConfiguredSources;
