import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@mui/material';


function DisplayCategories({groupedCategories, startCategory, endCategory}) {
  return (
    <>
      {Object.entries(groupedCategories).map(([category, subCategories]) => (
        <React.Fragment key={category}>
          {(parseInt(category) >= startCategory && parseInt(category) <= endCategory) &&
            <React.Fragment key={category}>
              <Typography key={category} variant="subtitle2" color="text.primary">Category {category}</Typography>
              {subCategories.map((subCategory) => (
                <Typography key={subCategory} variant="body2">{subCategory}</Typography>
              ))}
            </React.Fragment>
          }
        </React.Fragment>
      ))}
    </>
  );
}


DisplayCategories.propTypes = {
  groupedCategories: PropTypes.object,
  startCategory: PropTypes.number,
  endCategory: PropTypes.number
};

export default DisplayCategories;
