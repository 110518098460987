import React from 'react';
import {Typography, styled, Button, Box} from '@mui/material';

const ErrorWrapper = styled(Box)(
  () => `

    padding-top: 10px;
    text-align: center;
    margin: 0 auto;
  `
);

function LoadError() {
  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <ErrorWrapper>
      <Typography variant="body1" color="text.secondary" sx={{mb: 2, fontSize: '15px'}}>We’re having trouble loading the content, please try again.</Typography>
      <Button size="small" variant="contained" color="primary" onClick={refreshPage}>Retry</Button>
    </ErrorWrapper>
  );
}

export default LoadError;
