import React from 'react';
import {MenuItem, Select, Box} from '@mui/material';
import PropTypes from 'prop-types';


function PageSizeSelect({pageSize, setNewPageSize}) {
  return (
    <Box className="page-size-select-container">
      <Select
        disablescrolllock='true'
        className="page-size-select" value={pageSize} onChange={setNewPageSize}>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    </Box>
  );
}

PageSizeSelect.propTypes = {
  pageSize: PropTypes.number.isRequired,
  setNewPageSize: PropTypes.func.isRequired
};

export default PageSizeSelect;
