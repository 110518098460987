import {Accordion, AccordionSummary, Box, Grid, Skeleton, Typography} from '@mui/material';
import {GridExpandMoreIcon} from '@mui/x-data-grid';

import CopyButton from 'src/components/CopyButton';


function AccordionLoader() {
  return (
    <>
      <Box className="accordion-header" sx={{mt: 3, display: 'flex', maxHeight: '42px'}}>Match Category  <Skeleton sx={{display: 'inline-block', mt: '-7px', ml: 1}} width={20} height={40} /></Box>
      <Accordion className="accordion" expanded={false}>
        <AccordionSummary sx={{flexDirection: 'row-reverse', alignItems: 'center'}} className="accordion-summary" expandIcon={<GridExpandMoreIcon className="disabled-accordion-arrow" />}>
          <Grid item xs={1}>
            <Skeleton sx={{display: 'inline-block', mt: '10px', ml: 1}} width={60} height={40} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body1">Source: <Skeleton sx={{display: 'inline-block', mt: '2px', ml: 1}} width={450} height={20} /></Typography>
            <Typography variant="body1"> Match ID: <CopyButton paddingLeft="15px" display="inline" disabled /> <Skeleton sx={{display: 'inline-block', mt: '-7px', ml: 1}} width={400} height={20} /></Typography>
          </Grid>
        </AccordionSummary>
      </Accordion>
    </>
  );
}

export default AccordionLoader;
