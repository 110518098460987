export const filterItems = (item, tiers) => {
  if (!tiers || tiers.length === 0) {
    if (item?.tiers?.includes('standard')) {
      return item;
    }
  }
  if (tiers) {
    for (const tier of tiers) {
      if (item?.tiers?.includes(tier)) {
        return item;
      }
    }
  }
};
