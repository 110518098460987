import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';

import DisplayCategories from './displayCategories';


const groupCategories = function(accumulator, category) {
  const topCategory = parseInt(category);
  const subCategories = accumulator[topCategory] || [];

  subCategories.push(category);

  accumulator[topCategory] = subCategories;

  return accumulator;
};

function DisplayGroupedCategories({categories}) {
  const groupedCategories = categories.reduce(groupCategories, {});
  const categoryGroups = [{start: 1, end: 7}, {start: 8, end: 14}, {start: 15, end: 21}, {start: 22, end: 26}];

  return (
    <>
      {categoryGroups.map(({start, end}) => (
        <Grid item xs={3} key={start}>
          <DisplayCategories groupedCategories={groupedCategories} startCategory={start} endCategory={end} />
        </Grid>
      ))}
    </>
  );
}


DisplayGroupedCategories.propTypes = {
  categories: PropTypes.array
};

export default DisplayGroupedCategories;
