import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import {Grid, Box, styled, ThemeProvider} from '@mui/material';

import {EvadataTheme} from 'src/theme/schemes/EvadataTheme';
import {GreenFieldsTheme} from 'src/theme/schemes/GreenFieldsTheme';
import ThemeSettings from 'src/components/ThemeSettings';
import Footer from './Footer';


const MainContentWrapper = styled(Box)(
  () => `
    margin-bottom: 20px;
    text-align: center;
    min-height: calc(100vh - 190px);

    @media screen and (max-width: 1279px) {
      margin-left: 0px;
    }
`
);

function BaseLayout({children, appBar}) {
  const curThemeName = localStorage.getItem('appTheme') || 'EvadataTheme';

  useEffect(() => {
    localStorage.setItem('appTheme', data);
  });

  const [data, setData] = useState(curThemeName);

  const childToParent = (childData) => {
    setData(childData);
  };

  return (
    <Box>
      <Grid className='primary-layout' container spacing={2} justifyContent="center" textAlign="left">
        <ThemeProvider theme={data === 'EvadataTheme' ? EvadataTheme : GreenFieldsTheme}>
          <CssBaseline />
          {appBar}
          <Grid item sm={12} sx={{mt: '80px'}}>
            <MainContentWrapper sx={{px: 3, textAlign: 'left'}}>
              {children}
            </MainContentWrapper>
            <Footer />
          </Grid>
          <ThemeSettings childToParent={childToParent}/>
        </ThemeProvider>
      </Grid>
    </Box>
  );
}

BaseLayout.propTypes = {
  children: PropTypes.node,
  appBar: PropTypes.element
};

export default BaseLayout;
