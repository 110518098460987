import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid} from '@mui/material';


function AppBar({children}) {
  return (
    <Grid item xs={12} sx={{backgroundColor: '#fff'}}>
      <Box>
        {children}
      </Box>
    </Grid>
  );
}

AppBar.propTypes = {
  children: PropTypes.node
};

export default AppBar;
