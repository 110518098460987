import PropTypes from 'prop-types';
import {Chip, Skeleton} from '@mui/material';

import {statusMap} from 'src/utilities/statusMap';


const colorMap = {
  'processing': 'info',
  'skipped': 'default',
  'fileFailed': 'error',
  'pendingReview': 'default',
  'queuedForDelivery': 'default',
  'reviewInProgress': 'warning',
  'reviewCompleted': 'success',
  'fileDelivered': 'success',
  'delivered': 'success',
  'deliveryFailed': 'error',
  // not sure if the following are  fileStatus related
  'failed': 'error',
  'pending': 'default',
  'completed': 'success'
};

function FileStatusChip({fileStatus, isDataLoading, ...props}) {
  const label = statusMap[fileStatus];
  const color = colorMap[fileStatus];

  if (isDataLoading) {
    return (
      <Skeleton width={140} height={30} />
    );
  }

  return (
    <Chip variant="outlined" color={color} label={label} {...props} />
  );
}

FileStatusChip.propTypes = {
  fileStatus: PropTypes.string,
  isDataLoading: PropTypes.bool
};

export default FileStatusChip;
