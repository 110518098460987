import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Typography, Grid, Button} from '@mui/material';
import {Add} from '@mui/icons-material';


function Header({disable}) {
  return (
    <Grid container sx={{mb: 4}}>
      <Grid item xs={6}>
        <Typography variant="h5">Automations</Typography>
      </Grid>
      <Grid item xs={6} sx={{textAlign: 'right'}}>
        <Button disabled={disable} sx={{mr: 2}} LinkComponent={Link} to={'execute'} variant="outlined">
          Run Automation
        </Button>
        <Button LinkComponent={Link} to={'add'} variant="contained" startIcon={<Add />} disabled={disable}>
          Add Automation
        </Button>

      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  disable: PropTypes.bool.isRequired
};

export default Header;
