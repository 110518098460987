import React, {useContext} from 'react';

import {Box, Drawer, styled, useTheme, Tooltip, IconButton} from '@mui/material';
import {MenuRounded} from '@mui/icons-material';

import Scrollbar from '../../../Scrollbar';
import {SidebarContext} from '../contexts/SidebarContext';
import SidebarMenu from './SidebarMenu';
import SidebarBottomButton from './BottomButton';


const ToggleButton = styled(Box)(
  ({theme}) => `
    background-color: ${theme.palette.primary.main};
    position: fixed;
    top: 10px;
    left: 15px;
    z-index: 1200;
    height: 41px;
    border-radius: 6px;

    button {
      color: ${theme.palette.primary.contrast};
    }

    :hover {
      background-color: ${theme.palette.primary.dark};
    }

    .toggle-icon {
      color: ${theme.palette.background.default};
    }

    @media screen and (max-width: 359px) {
      left: 3px;
    }
  `
);

const SidebarWrapper = styled(Box)(
  ({theme}) => `
    width: ${theme.sidebar.width};
    min-width: ${theme.sidebar.width};
    color: ${theme.sidebar.textColor};
    background: ${theme.sidebar.background};
    box-shadow: ${theme.sidebar.boxShadow};
    position: relative;
    height: 100%;

    @media screen and (max-width: 1080px) {
      box-shadow: none;
    }
  `
);

function Sidebar() {
  const {sidebarToggle, toggleSidebar} = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <ToggleButton className="toggleSidebarButton" onClick={toggleSidebar} sx={{display: 'block'}}>
        <Tooltip arrow disableFocusListener title={sidebarToggle ? 'Close Main Menu' : 'Open Main Menu'} placement="right" PopperProps={{style: {zIndex: 9999999}}}>
          <IconButton className="toggle-button" onClick={toggleSidebar} sx={{fontSize: '15px', lineHeight: '26px', letterSpacing: '0.46px'}}>
            <MenuRounded className="toggle-icon" />
            &nbsp;MENU
          </IconButton>
        </Tooltip>
      </ToggleButton>

      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
          zIndex: 1000
        }}
        ModalProps={{disableScrollLock: true}}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper sx={{
          display: 'inline-block',
          left: 0,
          top: 65,
          height: 'calc(100% - 167px)'
        }}>
          <Scrollbar>
            <SidebarMenu/>
          </Scrollbar>
          <SidebarBottomButton />
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
