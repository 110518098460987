import React from 'react';
import {Alert, Button, Grid} from '@mui/material';
import {Link} from 'react-router-dom';
import {useQuery} from 'react-query';
import PropTypes from 'prop-types';

import {getTransferMatchFile} from 'src/api';


function MatchFileDeliveryMessage({carrierId, dataLoadId}) {
  const {data, isFetched} = useQuery(['getSendReport', carrierId, dataLoadId], () => getTransferMatchFile(carrierId, dataLoadId), {enabled: !!dataLoadId});

  const deliveryDate = data?.scheduledDeliveryDate || new Date().toLocaleDateString('en', {month: '2-digit', day: '2-digit', year: 'numeric'});

  if (!isFetched) {
    return;
  }

  return (
    <Grid item xs={12}>
      <Alert action={
        <Button
          color="success"
          size="small"
          LinkComponent={Link}
          to={`/${carrierId}/all-match-results?tab=queuedForDelivery`}>
          VIEW FILE IN QUEUE
        </Button>
      } sx={{mb: 2}} severity="success">
        Match file moved to the queue and is scheduled for delivery {deliveryDate}.
      </Alert>
    </Grid>
  );
}

MatchFileDeliveryMessage.propTypes = {
  carrierId: PropTypes.string.isRequired,
  dataLoadId: PropTypes.string
};

export default MatchFileDeliveryMessage;
