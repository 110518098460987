import React from 'react';
import {useOutletContext} from 'react-router-dom';
import {Box, Grid} from '@mui/material';

import MetadataSection from './MetadataSection';
import ActionBar from './ActionBar';
import MatchResultsFileDetailsTable from './MatchResultsFileDetailsTable';


function AllMatchRecords() {
  const {carrier, matchJob} = useOutletContext();

  const netNewRecordsCount = matchJob?.newMatches || 0;
  const updatedRecordsCount = matchJob?.updatedMatches || 0;
  const deletedRecordsCount = matchJob?.deletedMatches || 0;
  const fileName = matchJob?.fileName;
  const dataLoadId = matchJob?.dataLoadId;
  const sources = matchJob?.sources || [];
  const categories = matchJob?.categories || [];
  const isVerified = carrier.isVerified;

  return (
    <Box sx={{display: 'flex'}}>
      <Box
        component="main"
        sx={{flexGrow: 1, width: '100%'}}
      >
        <MetadataSection
          fileName={fileName}
          dataloadId={dataLoadId}
          categories={categories}
          sources={sources}
          isVerified={isVerified}
        />
        <ActionBar
          verified={isVerified}
          netNewRecordsCount={netNewRecordsCount}
          updatedRecordsCount={updatedRecordsCount}
          deletedRecordsCount={deletedRecordsCount}
          categories={categories}
        />
        <Grid container spacing={3} >
          <Grid item xs={12}>
            <MatchResultsFileDetailsTable isVerified={isVerified}/>
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
}

export default AllMatchRecords;
