import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Grid, Tooltip, Typography} from '@mui/material';
import {Alarm, Cancel, CheckCircle, DoDisturbOn, Error, Help, History, Visibility, VisibilityOff} from '@mui/icons-material';


function RecordEntry({title, fieldName, customerListData, deathRecordData, exactMatch, fuzzyMatch, result, valid = true, historical, prevExactMatch, prevFuzzyMatch}) {
  const [showCustomerSsn, setShowCustomerSsn] = useState(false);
  const [showHistoricalCustomerSsn, setShowHistoricalCustomerSsn] = useState(false);
  const [showDeathRecordSsn, setShowDeathRecordSsn] = useState(false);
  const [showHistoricalDeathRecordSsn, setShowHistoricalDeathRecordSsn] = useState(false);
  const [stateMatch, setStateMatch] = useState('');
  const [displayStateLabel, setDisplayStateLabel] = useState(false);
  const [stateLabel, setStateLabel] = useState('');
  const [stateMatchIconValue, setStateMatchIconValue] = useState(false);
  const [additionalStateMatchesArray, setAdditionalStateMatchesArray] = useState(null);

  const [cityMatch, setCityMatch] = useState('');
  const [displayCityLabel, setDisplayCityLabel] = useState(false);
  const [cityLabel, setCityLabel] = useState('');
  const [cityMatchIconValue, setCityMatchIconValue] = useState(false);
  const [additionalCityMatchesArray, setAdditionalCityMatchesArray] = useState(null);
  const [ssnDots, setSsnDots] = useState('•••-••-••••');
  const [wasUpdated, setWasUpdated] = useState(false);

  const prevDeathRecord = historical?.deathRecord;

  const updatedTooltipText = 'Updated death record details from ' + result?.matchDate;

  const historicTooltipText = 'Historic death record details from ' + historical?.matchDate;

  const ExactMatchTooltipIcon = <Tooltip arrow title="Exact Match"><CheckCircle className="check-circle icon" /></Tooltip>;

  const NoMatchTooltipIcon = <Tooltip arrow title="No Match"><Cancel className="cancel icon" /></Tooltip>;

  const toggleCustomerSsnVisibility = () => {
    if (!showCustomerSsn) {
      setShowCustomerSsn(true);
      setTimeout(() => setShowCustomerSsn(false), 5000);
    } else {
      setShowCustomerSsn(false);
    }
  };

  const toggleHistoricalCustomerSsnVisibility = () => {
    if (!showHistoricalCustomerSsn) {
      setShowHistoricalCustomerSsn(true);
      setTimeout(() => setShowHistoricalCustomerSsn(false), 5000);
    } else {
      setShowHistoricalCustomerSsn(false);
    }
  };

  const toggleDeathRecordSsnVisibility = () => {
    if (!showDeathRecordSsn) {
      setShowDeathRecordSsn(true);
      setTimeout(() => setShowDeathRecordSsn(false), 5000);
    } else {
      setShowDeathRecordSsn(false);
    }
  };

  const toggleHistoricalDeathRecordSsnVisibility = () => {
    if (!showHistoricalDeathRecordSsn) {
      setShowHistoricalDeathRecordSsn(true);
      setTimeout(() => setShowHistoricalDeathRecordSsn(false), 5000);
    } else {
      setShowHistoricalDeathRecordSsn(false);
    }
  };

  useEffect(() => {
    if (title === 'State') {
      const arrayOfStateMatches = [];
      const addToArrayofStateMatches = (label, matchValue, isExact) => {
        arrayOfStateMatches.push(
          {
            label: label,
            matchValue: matchValue,
            iconValue: isExact
          }
        );
      };
      if (deathRecordData) {
        addToArrayofStateMatches('Found in Residence State', deathRecordData, result?.matchMetadata?.stateExactMatch?.residenceState);
      }
      if (result?.deathRecord?.funeralServiceState) {
        addToArrayofStateMatches('Found in Funeral Service State', result?.deathRecord?.funeralServiceState, result?.matchMetadata?.stateExactMatch?.funeralServiceState);
      }
      if (result?.deathRecord?.placeOfBirthState) {
        addToArrayofStateMatches('Found in Place of Birth State', result?.deathRecord?.placeOfBirthState, result?.matchMetadata?.stateExactMatch?.placeOfBirthState);
      }
      if (result?.deathRecord?.placeOfDeathState) {
        addToArrayofStateMatches('Found in Place of Death State', result?.deathRecord?.placeOfDeathState, result?.matchMetadata?.stateExactMatch?.placeOfDeathState);
      }

      if (arrayOfStateMatches.length > 0) {
        setStateLabel(arrayOfStateMatches[0]?.label);
        setStateMatch(arrayOfStateMatches[0]?.matchValue);
        setStateMatchIconValue(arrayOfStateMatches[0]?.iconValue);
        const newArray = arrayOfStateMatches.slice(1);
        if (newArray.length > 0) {
          setStateLabel(arrayOfStateMatches[0]?.label);
          setAdditionalStateMatchesArray(newArray);
        }
      }
      setDisplayStateLabel(true);
    }
  }, [customerListData, deathRecordData]);

  useEffect(() => {
    if (title === 'City') {
      const arrayOfCityMatches = [];
      const addToArrayofCityMatches = (label, matchValue, isExact) => {
        arrayOfCityMatches.push(
          {
            label: label,
            matchValue: matchValue,
            iconValue: isExact
          }
        );
      };
      if (deathRecordData) {
        addToArrayofCityMatches('Found in Residence City', deathRecordData, result?.matchMetadata?.cityExactMatch?.residenceCity);
      }
      if (result?.deathRecord?.funeralServiceCity) {
        addToArrayofCityMatches('Found in Funeral Service City', result?.deathRecord?.funeralServiceCity, result?.matchMetadata?.cityExactMatch?.funeralServiceCity);
      }
      if (result?.deathRecord?.newspaperCity) {
        addToArrayofCityMatches('Found in Newspaper City', result?.deathRecord?.newspaperCity, result?.matchMetadata?.cityExactMatch?.newspaperCity);
      }
      if (result?.deathRecord?.placeOfDeathCity) {
        addToArrayofCityMatches('Found in Place of Death City', result?.deathRecord?.placeOfDeathCity, result?.matchMetadata?.cityExactMatch?.placeOfDeathCity);
      }

      if (arrayOfCityMatches.length > 0) {
        setCityLabel(arrayOfCityMatches[0]?.label);
        setCityMatch(arrayOfCityMatches[0]?.matchValue);
        setCityMatchIconValue(arrayOfCityMatches[0]?.iconValue);
        const newArray = arrayOfCityMatches.slice(1);
        if (newArray.length > 0) {
          setCityLabel(arrayOfCityMatches[0]?.label);
          setAdditionalCityMatchesArray(newArray);
        }
      }
      setDisplayCityLabel(true);
    }
  }, [customerListData, deathRecordData]);

  const matchIcon = () => {
    if (!customerListData && deathRecordData && valid) {
      return NoMatchTooltipIcon;
    }
    if (!deathRecordData) {
      return <><Tooltip arrow title="Null/No Value"><DoDisturbOn className="null icon" /></Tooltip> &ndash; </>;
    }
    if (!valid) {
      return <Tooltip arrow title="Invalid Match"><Error className="error icon" /></Tooltip>;
    }
    if (exactMatch) {
      return ExactMatchTooltipIcon;
    }
    if (fuzzyMatch) {
      return <Tooltip arrow title="Fuzzy Match"><Help className="help icon" /></Tooltip>;
    }
    if (!exactMatch && !fuzzyMatch) {
      return NoMatchTooltipIcon;
    }
  };

  useEffect(() => {
    if (title === 'SSN' && !valid) {
      const ssnString = deathRecordData?.replace(/\d/g, '•');
      setSsnDots(ssnString);
    }
  }, [deathRecordData, title, valid]);

  useEffect(() => {
    if (prevDeathRecord) {
      const historicalData = Object.keys(prevDeathRecord);
      if (historicalData?.includes(fieldName) && (prevDeathRecord?.[fieldName] !== deathRecordData)) {
        setWasUpdated(true);
      }
    }
  }, [deathRecordData, prevDeathRecord, fieldName]);

  const updatedMatchIcon = () => {
    let icon;
    if (!prevDeathRecord[fieldName]) {
      icon =
        <><Tooltip arrow title="Null/No Value"><DoDisturbOn className="null icon" /></Tooltip> &ndash; </>;
      return icon;
    }
    if (prevExactMatch) {
      icon = ExactMatchTooltipIcon;
      return icon;
    }
    if (prevFuzzyMatch) {
      icon = <Tooltip arrow title="Fuzzy Match"><Help className="help icon" /></Tooltip>;
      return icon;
    }
    if (!prevExactMatch && !prevFuzzyMatch) {
      icon = NoMatchTooltipIcon;
      return icon;
    }
    return icon;
  };

  return (
    <Box className="record-entry-container">
      <Box className="record-entry-header">
        <Grid container>
          <Grid item xs={6} sx={{display: 'flex', alignContent: 'center'}}>
            <Typography sx={{py: '4px', pl: '11px'}} variant="subtitle1">{title}</Typography>
            {wasUpdated &&
              <Tooltip title={updatedTooltipText} arrow placement="right"><Alarm sx={{mt: '6px', ml: 1}} color="primary" /></Tooltip>
            }
          </Grid>
          {(displayStateLabel || displayCityLabel) &&
            <Grid item xs={6} sx={{borderLeft: '1px solid #ccc'}} >
              <Typography variant="body2" sx={{pt: '8px', pl: 2}}>{displayStateLabel && stateLabel}{displayCityLabel && cityLabel}</Typography>
            </Grid>}
        </Grid>
      </Box>
      <Box>
        <Grid container sx={{height: 'auto'}}>
          <Grid item xs={6} sx={{pt: 1, pl: 1}}>
            {title !== 'SSN' && <Typography display="inline" variant="body1">{customerListData}</Typography>}
            {title === 'SSN' &&
              <>
                {!customerListData && <><Tooltip arrow title="Null/No Value"><DoDisturbOn className="null icon" /></Tooltip> &ndash; </>}
                {!valid && <Tooltip arrow title="Invalid Match"><Error className="error icon" /></Tooltip>}
                {customerListData && <Typography display="inline" variant="body1">{showCustomerSsn ? customerListData : '•••-••-••••'}</Typography>}
                {customerListData && <Button className="ssn-btn" onClick={toggleCustomerSsnVisibility}>{showCustomerSsn ? <Visibility className="ssn-btn-icon" /> : <VisibilityOff className="ssn-btn-icon" />}</Button>}
              </>
            }
          </Grid>
          <Grid item xs={6} sx={{borderLeft: '1px solid #ccc'}}>
            <Typography variant="body1" className={title !== 'State' && title !== 'City' ? 'right-column-style' : ''}>
              {title !== 'State' && title !== 'City' &&
                matchIcon()
              }

              {title !== 'SSN' && title !== 'State' && title !== 'City' && <Typography display="inline" variant="body1">{deathRecordData}</Typography>}

              {title === 'SSN' && deathRecordData &&
                <>
                  <Typography display="inline" variant="body1">{showDeathRecordSsn ? deathRecordData : ssnDots}</Typography>
                  <Button className="ssn-btn" onClick={toggleDeathRecordSsnVisibility}>{showDeathRecordSsn ? <Visibility className="ssn-btn-icon" /> : <VisibilityOff className="ssn-btn-icon" />}</Button>
                </>
              }

              {title === 'State' &&
                (additionalStateMatchesArray?.length > 0 || stateMatch ?
                  <>
                    <Box className="right-column-style">
                      {stateMatchIconValue ? ExactMatchTooltipIcon : NoMatchTooltipIcon}
                      <Typography display="inline" variant="body1">{stateMatch}</Typography>
                    </Box>
                    <Box>
                      {additionalStateMatchesArray?.map((item) => (
                        <div key={item.label}>
                          <Typography variant="body2" className="multi-entry-header">{item.label}</Typography>
                          <Typography sx={{height: '40px', pt: 1, pl: 1}}>{item.iconValue ? ExactMatchTooltipIcon : NoMatchTooltipIcon}{item.matchValue}</Typography>
                        </div>
                      ))}
                    </Box>
                  </> :
                  <Box className="right-column-style">
                    <><Tooltip arrow title="Null/No Value"><DoDisturbOn className="null icon" /></Tooltip> &ndash; </>
                  </Box>
                )
              }

              {title === 'City' &&
                (additionalCityMatchesArray?.length > 0 || cityMatch ?
                  <>
                    <Box className="right-column-style">
                      {cityMatchIconValue ? ExactMatchTooltipIcon : NoMatchTooltipIcon}
                      <Typography display="inline" variant="body1">{cityMatch}</Typography>
                    </Box>
                    <Box>
                      {additionalCityMatchesArray?.map((item) => (
                        <div key={item.label}>
                          <Typography className="multi-entry-header" variant="body2">{item.label}</Typography>
                          <Typography sx={{height: '40px', pt: 1, pl: 1}}>{item.iconValue ? ExactMatchTooltipIcon : NoMatchTooltipIcon}{item.matchValue}</Typography>
                        </div>
                      ))}
                    </Box>
                  </> :
                  <Box className="right-column-style">
                    <><Tooltip arrow title="Null/No Value"><DoDisturbOn className="null icon" /></Tooltip> &ndash; </>
                  </Box>
                )
              }
            </Typography>
          </Grid>


        </Grid>
      </Box>
      {wasUpdated &&
        <>
          <Box className="record-entry-header historic">
            <Grid container>
              <Grid item xs={6} sx={{display: 'flex', alignContent: 'center'}}>
                <Typography sx={{py: '4px', pl: '11px'}} variant="subtitle1">{title}</Typography>
                <Tooltip title={historicTooltipText} arrow placement="right"><History sx={{mt: '6px', ml: 1}} color="primary" /></Tooltip>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container sx={{height: 'auto'}}>
              <Grid item xs={6} sx={{pt: 1, pl: 1}}>
                {title !== 'SSN' && <Typography display="inline" variant="body1">{customerListData}</Typography>}
                {title === 'SSN' &&
                  <>
                    {!valid && <Tooltip arrow title="Invalid Match"><Error className="error icon" /></Tooltip>}
                    <Typography display="inline" variant="body1">{showHistoricalCustomerSsn ? customerListData : '•••-••-••••'}</Typography>
                    <Button className="ssn-btn" onClick={toggleHistoricalCustomerSsnVisibility}>{showHistoricalCustomerSsn ? <Visibility className="ssn-btn-icon" /> : <VisibilityOff className="ssn-btn-icon" />}</Button>
                  </>
                }
              </Grid>
              <Grid item xs={6} sx={{borderLeft: '1px solid #ccc', pt: 1, pl: 1, pb: 1}}>
                <Box>
                  {title === 'SSN' ?
                    <>
                      <Typography display="inline" variant="body1">{updatedMatchIcon()}{showHistoricalDeathRecordSsn ? deathRecordData : ssnDots}</Typography>
                      <Button className="ssn-btn" onClick={toggleHistoricalDeathRecordSsnVisibility}>{showHistoricalDeathRecordSsn ? <Visibility className="ssn-btn-icon" /> : <VisibilityOff className="ssn-btn-icon" />}</Button>
                    </> :
                    <Typography display="inline" variant="body1">{updatedMatchIcon()}{prevDeathRecord?.[fieldName]}</Typography>
                  }
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      }
    </Box>
  );
}

RecordEntry.propTypes = {
  title: PropTypes.string,
  fieldName: PropTypes.string,
  customerListData: PropTypes.string,
  deathRecordData: PropTypes.string,
  exactMatch: PropTypes.bool,
  fuzzyMatch: PropTypes.bool,
  result: PropTypes.object,
  valid: PropTypes.bool,
  historical: PropTypes.object,
  prevExactMatch: PropTypes.bool,
  prevFuzzyMatch: PropTypes.bool
};

export default RecordEntry;
