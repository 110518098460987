import React from 'react';
import PropTypes from 'prop-types';
import {Grid, IconButton, Alert} from '@mui/material';
import {Close} from '@mui/icons-material';


function ApiError(props) {
  return (
    <Grid item xs={12} sx={{m: 3, mb: 0}}>
      <Alert severity="error" onClick={() => props.setApiError(false)} action={
        <IconButton
          sx={{fontSize: '13px', mt: '2px', mr: '2px'}}
          aria-label="close"
          size="small"
          color="error"
        >
          CLOSE <Close sx={{ml: '3px', fontSize: '17px'}} color='error' className='close-icon' fontSize="inherit" />
        </IconButton>
      }>We’re having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
    </Grid>
  );
}

ApiError.propTypes = {
  setApiError: PropTypes.func.isRequired
};

export default ApiError;
