import {Button} from '@mui/material';
import {RefreshRounded} from '@mui/icons-material';


function RefreshButton() {
  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <Button onClick={refreshPage} variant="contained" color="primary" startIcon={<RefreshRounded />}>Refresh</Button>
  );
}

export default RefreshButton;
