import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray} from 'formik';
import {Box, Button, FormControl, FormHelperText, Grid, MenuItem, Select, Skeleton, styled, Typography} from '@mui/material';
import {AddCircleOutline, RemoveCircleOutline} from '@mui/icons-material';


const SelectWrapper = styled(Select)(
  ({theme}) => `
  display: inline-block;
  min-width: 185px;
  margin-bottom: 10px;
  margin-top: 10px;
  .MuiTypography-root.placeholder {
    color: ${theme.palette.text.disabled}
}
  `
);

function AutomationConditions({isLoading, values, fieldName, onChange, touched, errors}) {
  return (
    <Box sx={{ml: 4, mr: 0, mb: 3}}>
      <FieldArray
        name={fieldName}
        render={(arrayHelpers) => (
          <>
            {values?.[fieldName]?.map((condition, index) => (
              <Grid key={`container-${condition}`} container>
                <Grid item xs={12}>
                  <hr />
                </Grid>
                <Grid key={`container-item-${condition}`} item sm={10}>
                  <Typography display="inline-block" sx={{mr: 2, mt: '25px'}}>and</Typography>
                  {isLoading ?
                    <Skeleton sx={{display: 'inline-block'}} width={185} height={20} /> :
                    <FormControl error={Boolean(touched?.[fieldName]?.[index]?.['match']) && Boolean(errors?.[fieldName]?.[index]?.['match'])}>
                      <SelectWrapper
                        MenuProps={{
                          disableScrollLock: true
                        }}
                        displayEmpty
                        key={values[fieldName]?.[index]}
                        labelId="condition-attribute-select-label"
                        id={`condition-attribute-${index}`}
                        name={`${fieldName}[${index}]['attribute']`}
                        value={values[fieldName]?.[index]?.['attribute']}
                        onChange={onChange}
                      >
                        <MenuItem disabled value=''><Typography className="placeholder" sx={{fontStyle: 'italic'}}>Select</Typography></MenuItem>
                        <MenuItem value='ssn'>SSN</MenuItem>
                        <MenuItem value='dob'>DOB</MenuItem>
                        <MenuItem value='firstName'>First Name</MenuItem>
                        <MenuItem value='lastName'>Last Name</MenuItem>
                      </SelectWrapper>
                      {(touched?.[fieldName]?.[index]?.['match'] && errors?.[fieldName]?.[index]?.['match']) && <FormHelperText error>Selection is required</FormHelperText>}
                    </FormControl>
                  }
                  <Typography display="inline" sx={{ml: 2, mr: 2}}>is</Typography>
                  {isLoading ?
                    <Skeleton sx={{display: 'inline-block'}} width={260} height={20} /> :
                    <FormControl error={Boolean(touched?.[fieldName]?.[index]?.['match']) && Boolean(errors?.[fieldName]?.[index]?.['match'])}>
                      <SelectWrapper
                        MenuProps={{
                          disableScrollLock: true
                        }}
                        displayEmpty
                        key={values[fieldName]?.[index]}
                        labelId="condition-match-select-label"
                        id={`condition-match-${index}`}
                        name={`${fieldName}[${index}]['match']`}
                        value={values[fieldName]?.[index]?.['match']}
                        onChange={onChange}
                      >
                        <MenuItem disabled value=''><Typography className="placeholder" sx={{fontStyle: 'italic'}}>Select</Typography></MenuItem>
                        <MenuItem value='exact'>Exact Match</MenuItem>
                        <MenuItem value='fuzzy'>Fuzzy Match</MenuItem>
                        <MenuItem value='both'>Exact or Fuzzy Match</MenuItem>
                      </SelectWrapper>
                      {(touched?.[fieldName]?.[index]?.['match'] && errors?.[fieldName]?.[index]?.['match']) && <FormHelperText error>Selection is required</FormHelperText>}
                    </FormControl>
                  }
                </Grid>
                {!isLoading &&
                  <Grid key={`container-item-delete-${condition}`} item sm={2} sx={{pt: 2, textAlign: 'right'}}>
                    <Box>
                      {values[fieldName].length > 1 &&
                        <Button key={`delete-button-${condition}`} variant="text" onClick={() => arrayHelpers.remove(index)} ><RemoveCircleOutline sx={{fontSize: '26px'}} color="error" key={`close-rounded-${condition}`} /></Button>
                      }
                      {values[fieldName].length < 4 &&
                        <Button sx={{ml: (values[fieldName].length > 1) ? 0 : 7}} variant="text" className="add-new-email" key="add" onClick={() => arrayHelpers.push({'attribute': '', 'match': ''})} ><AddCircleOutline sx={{fontSize: '26px'}} /></Button>
                      }
                    </Box>
                  </Grid>
                }
              </Grid>

            ))}
          </>
        )}
      />
      <hr />
    </Box>
  );
}

AutomationConditions.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  fieldName: PropTypes.string.isRequired
};

export default AutomationConditions;
