import {styled, Box} from '@mui/material';


const AccordionWrapper = styled(Box)(
  ({theme}) => `
    .accordion-header {
      background-color: ${theme.palette.action.disabled};
      color: ${theme.palette.primary.contrastText};
      padding: 8px 16px;
      border-radius: 6px 6px 0px 0px;
    }
    .accordion {
      border: 1px solid ${theme.palette.action.disabled};
      border-top: none;
      min-height: 70px;
    }
    .accordion-summary {
      height: 70px;
      border-radius: 0px;
    }
    .accordion-summary.Mui-expanded {
      background-color: ${theme.palette.action.focus};
    }
    .column-header {
      border: 1px solid #ccc;
      border-radius: 6px 6px 0px 0px;
      border-bottom: 0px;
    }
    .record-entry-container {
      border: 1px solid #ccc;
      margin-top: 10px;
    }
    .record-entry-header {
      background-color: ${theme.palette.action.hover};
    }
    .record-entry-header.historic {
      background-color: ${theme.palette.action.focus};
      width: 100%;
    }
    .record-entry-data {
      padding-left: 12px;
    }
    .icon {
       float: left;
       margin-right: 4px;
    }
    .null {
      color: ${theme.palette.text.disabled};
    }
    .error {
      color: ${theme.palette.warning.main};
    }
    .check-circle {
      color: ${theme.palette.success.main};
    }
    .help {
      color: ${theme.palette.info.main}; 
    }
    .cancel {
      color: ${theme.palette.error.main}; 
    }
    .link-button{
      padding-top: 0px;
      padding-left: 0px;
    }
    .link-button:hover {
      background: transparent;
      text-decoration: underline;
    }

    .right-column-style {
      padding: 8px 6px;
    }

    .multi-entry-header {
      background-color: ${theme.palette.action.hover};
      min-height: 40px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 18px;
      border-top: 1px solid ${theme.palette.action.focus};
    }
    .obit-details {
      border: 1px solid ${theme.palette.action.focus};
    }
    .obit-details-header {
      background-color: ${theme.palette.action.hover};
      border-bottom: 1px solid ${theme.palette.action.focus};
    }
  `
);

export default AccordionWrapper;
