import React from 'react';
import PropTypes from 'prop-types';
import {Alert, IconButton} from '@mui/material';
import {Close} from '@mui/icons-material';


function AlertWithClose({clearCompletedAlert, automationName}) {
  return (
    <Alert sx={{mb: 3}} onClick={clearCompletedAlert} severity="success" action={
      <IconButton
        sx={{fontSize: '13px', mt: '2px', mr: '2px'}}
        className="alert-close"
        aria-label="close"
        color="inherit"
        size="small"
      >
        CLOSE <Close sx={{ml: '3px', fontSize: '17px'}} className='close-icon' fontSize="inherit" />
      </IconButton>
    }>{automationName} saved successfully and will run on new match results files from the configuration(s) shown.</Alert>
  );
}

AlertWithClose.propTypes = {
  clearCompletedAlert: PropTypes.func.isRequired,
  automationName: PropTypes.string.isRequired
};

export default AlertWithClose;
