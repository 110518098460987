import PropTypes from 'prop-types';
import {Box, Typography} from '@mui/material';
import {Block, Check} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';


function MatchDecisionButtons({updateStatus, fileStatus, updateType, matchDecision, newMatchDecision}) {
  return matchDecision === 'todo' ?
    <>
      <LoadingButton className='accept' onClick={() => updateStatus('accepted')} loading={newMatchDecision === 'accepted'} disabled={newMatchDecision === 'rejected'} loadingPosition='start' variant="outlined" size="small" color="success" startIcon={<Check />}>Accept</LoadingButton>
      <LoadingButton className='reject' onClick={() => updateStatus('rejected')} loading={newMatchDecision === 'rejected'} disabled={newMatchDecision === 'accepted'} loadingPosition='start' variant="outlined" size="small" color="error" startIcon={<Block />} sx={{ml: 1}}>Reject</LoadingButton>
    </> :
    <Box sx={{textTransform: 'uppercase', alignItems: 'center', display: 'flex', float: 'right', pt: '4px'}} variant="subtitle1">
      <Typography display="inline-block" color={matchDecision === 'accepted' ? 'success.main' : 'error.main'}>{matchDecision === 'accepted' ? <Check /> : <Block />}</Typography>
      <Typography sx={{mt: '-4px', ml: '4px'}} color={matchDecision === 'accepted' ? 'success.main' : 'error.main'} display='inline-block'>{matchDecision}</Typography>
      {fileStatus !== 'queuedForDelivery' && fileStatus !== 'fileDelivered' && fileStatus !== 'deliveryFailed' && updateType !== 'update' &&
        <LoadingButton className='undo' onClick={() => updateStatus('todo')} loadingPosition='start' loading={newMatchDecision === 'todo'} sx={{ml: 2, mt: '-4px'}} size="small" color="primary" variant="outlined" startIcon={<></>}>Undo</LoadingButton>
      }
    </Box>;
}

MatchDecisionButtons.propTypes = {
  updateStatus: PropTypes.func,
  fileStatus: PropTypes.string,
  updateType: PropTypes.string,
  matchDecision: PropTypes.string,
  newMatchDecision: PropTypes.string
};

export default MatchDecisionButtons;
