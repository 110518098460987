import React, {forwardRef} from 'react';

import PropTypes from 'prop-types';
import {Refresh} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';


const LoadingButtonWithRefresh = forwardRef(function LoadingButtonWithRefresh(props, ref) {
  const {refresh, ...otherProps} = props;
  return (
    <LoadingButton
      variant="outlined"
      sx={{borderRadius: '6px', pl: 0, pr: 0, mr: 2, width: '20px', height: '55px'}}
      ref={ref}
      onClick={refresh}
      {...otherProps}
    >
      <Refresh/>
    </LoadingButton>
  );
});


LoadingButtonWithRefresh.propTypes = {
  loading: PropTypes.bool,
  refresh: PropTypes.func
};

export default LoadingButtonWithRefresh;
