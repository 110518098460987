import React from 'react';
import PropTypes from 'prop-types';
import {Skeleton, Box, styled} from '@mui/material';

const SkeletonWrapper = styled(Box)(
  () => `
    .skel {
      margin: 18px 18px 22px 10px;
      float: left;
    }
  `
);

const customSkeleton = (width, index) => (
  <Skeleton key={index} variant="rectangular" sx={{width: width}} className="skel" />
);
const widthMapping = {
  processing: {
    verified: ['29%', '29%', '15%', '16%'],
    standard: ['29%', '29%', '15%', '16%']
  },
  prescan: {
    verified: ['29%', '29%', '15%', '16%'],
    standard: ['29%', '29%', '15%', '16%']
  },
  underReview: {
    verified: ['20%', '19.5%', '9.5%', '9.5%', '14%', '10%'],
    standard: ['20%', '19.5%', '9.5%', '9.5%', '14%', '10%']
  },
  queuedForDelivery: {
    verified: ['18%', '18%', '9%', '19%', '10%', '10%'],
    standard: ['22.5%', '22.5%', '11%', '15%', '10%']
  },
  delivered: {
    verified: ['17%', '17%', '7.5%', '17%', '8.5%', '10%', '10%'],
    standard: ['20%', '20.5%', '10%', '9.5%', '13%', '10%']
  }
};

const SkeletonRow = function({fileStatus, isVerified}) {
  const verifiedMapping = isVerified ? 'verified' : 'standard';
  const widths = widthMapping[fileStatus][verifiedMapping];

  return (
    <SkeletonWrapper>
      {widths?.map((width, index) => {
        return customSkeleton(width, index);
      })}
    </SkeletonWrapper>
  );
};

SkeletonRow.propTypes = {
  fileStatus: PropTypes.string,
  isVerified: PropTypes.bool
};

export default SkeletonRow;
