import React from 'react';
import PropTypes from 'prop-types';
import {Box, Card, CardContent, Skeleton, Typography} from '@mui/material';

import DisplayConfiguredSources from 'src/components/DisplayConfiguredSources';
import CopyButton from 'src/components/CopyButton';
import LoadError from 'src/components/LoadError';


function DisplayFileDetails({fileName, dataloadId, categories, sources, minHeight, apiError = false}) {
  return (
    <Card sx={{borderRadius: '7px', mb: 3, minHeight: minHeight}}>
      <CardContent>
        <Typography variant="overline" color="text.primary">
          File Details
        </Typography>
        {apiError ? <Box sx={{pt: 3}}><LoadError /></Box>:
          <>
            <Typography variant='subtitle1'>File Name: <Typography variant="body1" display="inline" >{fileName || <Skeleton variant='rectangular' width={250} height={15} sx={{display: 'inline-block', mt: '5px'}} />}</Typography></Typography>
            <Typography variant='subtitle1'>Dataload ID: <CopyButton toolTipText="Copy Dataload ID" paddingLeft="10px" display="inline" textToCopy={dataloadId} disabled={!dataloadId} /><Typography variant="body1" display="inline" >{dataloadId || <Skeleton variant='rectangular' width={250} height={15} sx={{display: 'inline-block', mt: '5px'}} />}</Typography></Typography>
            <DisplayConfiguredSources categories={categories} sources={sources} />
          </>
        }
      </CardContent>
    </Card>
  );
}

DisplayFileDetails.propTypes = {
  fileName: PropTypes.string,
  dataloadId: PropTypes.string,
  categories: PropTypes.array,
  sources: PropTypes.array,
  minHeight: PropTypes.string,
  apiError: PropTypes.bool
};

export default DisplayFileDetails;
