export default function getColumnVisibilityModelMap(verified) {
  return {
    processing: {
      fileName: true,
      id: true,
      status: true,
      completed: false,
      totalMatches: false,
      reviewCompleted: false,
      scheduledDeliveryDate: false,
      rejectedMatches: false
    },
    prescan: {
      fileName: true,
      id: true,
      status: false,
      completed: false,
      totalMatches: true,
      reviewCompleted: false,
      scheduledDeliveryDate: false,
      rejectedMatches: false
    },
    underReview: {
      fileName: true,
      id: true,
      status: true,
      completed: false,
      totalMatches: true,
      reviewCompleted: true,
      scheduledDeliveryDate: false,
      rejectedMatches: false
    },
    queuedForDelivery: {
      fileName: true,
      id: true,
      status: false,
      completed: false,
      totalMatches: true,
      reviewCompleted: false,
      scheduledDeliveryDate: true,
      rejectedMatches: verified
    },
    delivered: {
      fileName: true,
      id: true,
      status: true,
      completed: true,
      totalMatches: true,
      reviewCompleted: false,
      scheduledDeliveryDate: false,
      rejectedMatches: verified
    }
  };
}
