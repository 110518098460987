
import React, {useState, useEffect} from 'react';
import {Grid, Typography} from '@mui/material';
import {useQuery} from 'react-query';

import {getCarriers} from 'src/api';
import TableWithPagination from 'src/components/TableWithPagination';
import getColumns from './columns';
import {SkeletonRow} from './skeleton';
import {TableWrapper, TitleWrapper} from './styles';


function CustomerAccounts() {
  const [params, setParams] = useState({
    limit: 10,
    next: '',
    previous: ''
  });

  const [rowCount, setRowCount] = useState({
    rowStart: 1,
    rowEnd: params.limit
  });

  const {isError, isLoading, data} = useQuery(['allCarrierConfigurations', params], () => getCarriers(params.limit, params.previous, params.next));

  const nextPage = function() {
    setParams((previousParams) => {
      return {
        limit: previousParams.limit,
        next: data?.pagination?.next,
        previous: ''
      };
    });

    setRowCount((previousRowCount) => {
      return {
        rowStart: previousRowCount.rowStart + params.limit
      };
    });
  };

  const previousPage = function() {
    setParams((previousParams) => {
      return {
        limit: previousParams.limit,
        next: '',
        previous: data?.pagination?.previous
      };
    });

    setRowCount((previousRowCount) => {
      return {
        rowStart: previousRowCount.rowStart - params.limit
      };
    });
  };

  const setPageSize = (pageSize) => {
    setParams({
      limit: pageSize,
      next: '',
      previous: ''
    });

    setRowCount({
      rowStart: 1,
      rowEnd: pageSize
    });
  };

  useEffect(() => {
    if (!isLoading) {
      setRowCount((previousRowCount) => {
        return {
          rowStart: previousRowCount.rowStart,
          rowEnd: previousRowCount.rowStart + data?.results?.length - 1
        };
      });
    }
  }, [data, isLoading]);

  return (
    <>
      <TitleWrapper>
        <Grid container spacing={2}>
          <Grid className="customer-accounts-title" item xs={12} sm={7}>
            <Typography variant="h4" color="primary.text">Customer Accounts</Typography>
          </Grid>
        </Grid>
      </TitleWrapper>
      <TableWrapper>
        <Typography variant="h5" sx={{mb: 3}}>Periscope Customer Management</Typography>
        <TableWithPagination
          rows={data?.results}
          columns={getColumns()}
          isDataTableLoading={isLoading}
          rowHeight={58}
          getNextPage={nextPage}
          getPreviousPage={previousPage}
          next={data?.pagination?.next}
          prev={data?.pagination?.previous}
          pageSize={params?.limit}
          setPageSize={setPageSize}
          isApiError={isError}
          SkeletonRow={<SkeletonRow/>}
          rowStart={rowCount.rowStart}
          rowEnd={rowCount.rowEnd}
        />
      </TableWrapper>
    </>
  );
}

export default CustomerAccounts;
