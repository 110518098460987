import React from 'react';
import {Grid, Typography, Button, Box} from '@mui/material';
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import PropTypes from 'prop-types';

import PageSizeSelect from './pageSizeSelect';


function Pagination({isDataTableLoading, prev, getPrevPage, next, getNextPage, pageSize, setNewPageSize, recordSetStart, recordSetEnd}) {
  return (
    <Grid container>
      <Grid item xs={6}></Grid>
      <Grid item xs={6} sx={{pt: 1}}>
        <Box sx={{width: '90px', float: 'right'}}>
          <Button id="previous" className="pagination-btn" disabled={!prev || isDataTableLoading} onClick={getPrevPage}><ChevronLeft /></Button>
          <Button id="next" className="pagination-btn" disabled={!next || isDataTableLoading} onClick={getNextPage}><ChevronRight color="text.primary" /></Button>
        </Box>
        <Box sx={{float: 'right'}}>
          <Box sx={{float: 'left'}}>
            <Typography display="inline-block" variant="caption" color="text.secondary" sx={{mr: 1}}>Rows per page: </Typography>
          </Box>
          <Box sx={{float: 'left', paddingRight: '20px'}}>
            <PageSizeSelect pageSize={pageSize} setNewPageSize={setNewPageSize}/>
          </Box>
          <Box sx={{float: 'left'}}>
            <Typography variant="caption">{isDataTableLoading ? 0 : recordSetStart}-{isDataTableLoading ? '0 of 0' : recordSetEnd}</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

Pagination.propTypes = {
  isDataTableLoading: PropTypes.bool,
  next: PropTypes.string,
  prev: PropTypes.string,
  setNewPageSize: PropTypes.func,
  getNextPage: PropTypes.func,
  getPrevPage: PropTypes.func,
  recordSetStart: PropTypes.number,
  recordSetEnd: PropTypes.number,
  pageSize: PropTypes.number
};

export default Pagination;
