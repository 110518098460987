import React from 'react';
import PropTypes from 'prop-types';
import {Box, Alert, Typography} from '@mui/material';


function FormHeader({genericError}) {
  return (
    <Box sx={{p: 2, pb: 1}}>
      {genericError && <Alert severity="error" sx={{mb: 2}}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>}
      <Typography variant="h6" sx={{fontWeight: 700}}>Automation Details</Typography>
    </Box>
  );
}

FormHeader.propTypes = {
  genericError: PropTypes.bool.isRequired
};

export default FormHeader;
