import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, Paper} from '@mui/material';
import TableWithPagination from 'src/components/TableWithPagination';


function AutomationsTable({isDataTableLoading, rows, columns, rowHeight, columnVisibilityModel, pageSize, next, prev, isApiError, SkeletonRow, emptyMessage, handleSort, initialState, setParams}) {
  const [rowCount, setRowCount] = useState({
    rowStart: 1,
    rowEnd: pageSize
  });

  const nextPage = function() {
    setParams((previousParams) => {
      return {
        limit: previousParams.limit,
        next,
        previous: ''
      };
    });

    setRowCount((previousRowCount) => {
      return {
        rowStart: previousRowCount.rowStart + pageSize
      };
    });
  };

  const previousPage = function() {
    setParams((previousParams) => {
      return {
        limit: previousParams.limit,
        next: '',
        previous: prev
      };
    });

    setRowCount((previousRowCount) => {
      return {
        rowStart: previousRowCount.rowStart - pageSize
      };
    });
  };

  const setPageSize = (pageSize) => {
    setParams({
      limit: pageSize,
      next: '',
      previous: ''
    });

    setRowCount({
      rowStart: 1,
      rowEnd: pageSize
    });
  };

  useEffect(() => {
    if (!isDataTableLoading) {
      setRowCount((previousRowCount) => {
        return {
          rowStart: previousRowCount.rowStart,
          rowEnd: previousRowCount.rowStart + rows?.length - 1
        };
      });
    }
  }, [rows, isDataTableLoading]);


  return (
    <Grid container spacing={3} >
      <Grid item xs={12}>
        <Paper sx={{mb: 2, mt: 1}}>
          <TableWithPagination
            rows={rows}
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            isDataTableLoading={isDataTableLoading}
            rowHeight={rowHeight}
            getNextPage={nextPage}
            getPreviousPage={previousPage}
            next={next}
            prev={prev}
            pageSize={pageSize}
            setPageSize={setPageSize}
            isApiError={isApiError}
            SkeletonRow={SkeletonRow}
            rowStart={rowCount.rowStart}
            rowEnd={rowCount.rowEnd}
            emptyMessage={emptyMessage}
            handleSort={handleSort}
            initialState={initialState}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

AutomationsTable.propTypes = {
  isDataTableLoading: PropTypes.bool,
  rows: PropTypes.array,
  columns: PropTypes.array,
  rowHeight: PropTypes.any,
  columnVisibilityModel: PropTypes.object,
  pageSize: PropTypes.number,
  next: PropTypes.string,
  prev: PropTypes.string,
  isApiError: PropTypes.bool,
  emptyMessage: PropTypes.string,
  handleSort: PropTypes.func,
  initialState: PropTypes.object,
  SkeletonRow: PropTypes.object,
  setParams: PropTypes.func
};

export default AutomationsTable;
