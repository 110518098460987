import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid, Typography} from '@mui/material';
import {Link} from 'react-router-dom';

function Breadcrumb({breadcrumbLinks, currentPage, mb = 2}) {
  return (
    <Grid container spacing={2} sx={{mb: mb}}>
      <Grid className="customer-accounts-title" item xs={12} sx={{display: 'block'}}>
        <Typography color="text.secondary" sx={{mt: 1, mb: 2}} variant='body2'>
          {breadcrumbLinks.map((crumb) => (
            <Box key={crumb.link} sx={{display: 'inline'}}>
              <Link key={crumb.link} to={crumb.link}>{crumb.title}</Link><Typography display="inline"> / </Typography>
            </Box>
          ))}
          <Typography variant="body2" display="inline" color="text.primary">{currentPage}</Typography> </Typography>
      </Grid>
    </Grid>
  );
}

Breadcrumb.propTypes = {
  breadcrumbLinks: PropTypes.array,
  currentPage: PropTypes.string,
  mb: PropTypes.any
};

export default Breadcrumb;
