import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from '@mui/material';


function NameField({value, onBlur, onChange, errors, label, name, helperText = '', errorText = '', maxLength = 1000}) {
  return (
    <TextField
      fullWidth
      id={name}
      name={name}
      label={label}
      className={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      error={errors}
      helperText={errors ? (errorText || helperText) : helperText}
      sx={{mb: 3}}
      inputProps={{maxLength: maxLength}}
    />
  );
}

NameField.propTypes = {
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  errors: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  helperText: PropTypes.string,
  errorText: PropTypes.string,
  maxLength: PropTypes.number
};

export default NameField;
