import {useState} from 'react';
import PropTypes from 'prop-types';
import {
  styled,
  Box,
  Tooltip,
  IconButton
} from '@mui/material';
import {BrightnessHighRounded, Brightness4Rounded} from '@mui/icons-material';

const ThemeSettingsButton = styled(Box)(
  ({theme}) => `
    position: fixed;
    z-index: 999;
    right: 100px;
    top: 20px};

    .MuiSvgIcon-root {
      animation: pulse 0s infinite;
      transition: ${theme.transitions.create(['all'])};
    }
  `
);

const ThemeSettings = ({childToParent}) => {
  const curThemeName = localStorage.getItem('appTheme') || 'EvadataTheme';

  const [theme, setTheme] = useState(curThemeName);

  const changeTheme = () => {
    if (theme === 'EvadataTheme') {
      setTheme('GreenFieldsTheme');
      childToParent('GreenFieldsTheme');
    } else {
      setTheme('EvadataTheme');
      childToParent('EvadataTheme');
    }
  };

  return (
    <ThemeSettingsButton onClick={changeTheme}>
      <Tooltip arrow title={theme === 'EvadataTheme' ? 'Switch to Dark Mode' : 'Switch to Light Mode'}>
        <IconButton sx={{top: '-8px', borderRadius: '20px'}}>
          {theme === 'EvadataTheme' ? <Brightness4Rounded color="primary" /> : <BrightnessHighRounded color="primary" />}
        </IconButton>
      </Tooltip>
    </ThemeSettingsButton>
  );
};

ThemeSettings.propTypes = {
  childToParent: PropTypes.func
};

export default ThemeSettings;
