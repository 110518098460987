import React from 'react';
import {useOutletContext} from 'react-router-dom';
import {Typography} from '@mui/material';


const CompanyName = function() {
  const {carrier} = useOutletContext();

  return (
    <Typography varaint="subtitle1" sx={{fontWeight: 700}}>{carrier.name}</Typography>
  );
};

export default CompanyName;
