
export const sources = [
  {display: 'DMF', value: 'dmf'},
  {display: 'Carrier-to-Carrier', value: 'carrier'},
  {display: 'Obit Funeral Home', value: 'funeral-home-obituary'},
  {display: 'Obit Newspaper', value: 'newspaper-obituary'},
  {display: 'State', value: 'state'}
];

const _clearFilterQueryParams = function(searchParams) {
  searchParams.delete('sources');
  searchParams.delete('categories');
  searchParams.delete('matchType');
  searchParams.delete('matchDecision');

  return searchParams;
};

export const clearFilterQueryParams = function(searchParams, setSearchParams) {
  const _searchParams = _clearFilterQueryParams(searchParams);

  setSearchParams(_searchParams);
};

export const getFilterQueryParams = function(searchParams) {
  const selectedSources = searchParams.getAll('sources') || [];
  const selectedCategories = searchParams.getAll('categories') || [];
  const selectedMatchType = searchParams.get('matchType') || '';
  const selectedMatchDecision = searchParams.get('matchDecision') || '';

  const _selectedSources = selectedSources.map((source) => {
    return sources.find(({value}) => value === source);
  });

  return {selectedSources: _selectedSources, selectedCategories, selectedMatchType, selectedMatchDecision};
};

export const setFilterQueryParams = function(searchParams, setSearchParams, formValues) {
  const {selectedSources, selectedCategories, selectedMatchType, selectedMatchDecision} = formValues;

  const _searchParams = _clearFilterQueryParams(searchParams);

  selectedSources.forEach(({value}) => _searchParams.append('sources', value));
  selectedCategories.forEach((category) => _searchParams.append('categories', category));

  _searchParams.set('matchType', selectedMatchType);
  _searchParams.set('matchDecision', selectedMatchDecision);

  setSearchParams(_searchParams);
};
