import React from 'react';

import PropTypes from 'prop-types';
import {Tooltip} from '@mui/material';
import LoadingButtonWithRefresh from './loadingButtonWithRefresh';


function LoadingButtonWithToolTip(props) {
  if (props?.loading) {
    return (
      <LoadingButtonWithRefresh loading={props?.loading} refresh={props?.refresh}/>
    );
  }

  // See https://github.com/mui/material-ui/issues/31261#issuecomment-1061101995
  return (
    <Tooltip title="Refresh Table" arrow>
      <LoadingButtonWithRefresh loading={props?.loading} refresh={props?.refresh}/>
    </Tooltip>
  );
}

LoadingButtonWithToolTip.propTypes = {
  loading: PropTypes.bool,
  refresh: PropTypes.func
};

export default LoadingButtonWithToolTip;
