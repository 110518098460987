import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Checkbox, FormControlLabel, Grid, Typography} from '@mui/material';
import {useParams, Link} from 'react-router-dom';
import {Field} from 'formik';
import {ErrorOutline} from '@mui/icons-material';


function FormFooter({isSubmitting, touched, errors, setShowErrorAlert, setFormikErrors}) {
  const {carrierId} = useParams();

  const validateErrors = () => {
    if (errors) {
      setFormikErrors(errors);
      setShowErrorAlert(true);
    }
  };

  return (
    <Box sx={{p: 2, pb: 3}}>
      <Grid container>
        {!isSubmitting &&
          <Grid item xs={12} sx={{textAlign: 'center', mb: 3}}>
            <Box sx={{maxWidth: '906px', mx: 'auto', textAlign: 'left'}}>
              <Field as={FormControlLabel} sx={{display: 'inline-block'}} type="checkbox" name='agreement' defaultValue='' control={<Checkbox value=''/>}/>
              <Typography variant="body1" sx={{display: 'inline-block', mt: 1}}>
                After saving, I understand that this automation will run on new Match Results files from the chosen configurations.
              </Typography>
              {(touched?.agreement && errors?.agreement) && <Typography color="error" variant="body2"><ErrorOutline sx={{display: 'inline-block', fontSize: '18px', mr: '4px', mt: '4px'}} />*Selection is required.
              </Typography>}
            </Box>
          </Grid>
        }
        <Grid item xs={6}>
          <Button LinkComponent={Link} to={`/${carrierId}/automations`} variant="outlined" color="primary" disabled={isSubmitting}>Cancel</Button>
        </Grid>
        <Grid item xs={6} sx={{textAlign: 'right'}}>
          <Button disabled={isSubmitting} onClick={validateErrors} type="submit" variant="contained" color="primary" >Save Automation</Button>
        </Grid>
      </Grid>
    </Box>
  );
}

FormFooter.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setShowErrorAlert: PropTypes.func.isRequired,
  setFormikErrors: PropTypes.func.isRequired
};

export default FormFooter;
