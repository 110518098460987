import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Grid, Typography} from '@mui/material';
import {ArrowBack} from '@mui/icons-material';


function Header() {
  return (
    <Grid container sx={{mb: 4}}>
      <Grid item xs={6}>
        <Typography variant="h5">Run Automation</Typography>
      </Grid>
      <Grid item xs={6} sx={{textAlign: 'right'}}>
        <Button LinkComponent={Link} to='./..' variant="outlined" startIcon={<ArrowBack />}>Back to Automations</Button>
      </Grid>
    </Grid>
  );
}

export default Header;
