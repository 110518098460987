import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {useNavigate, useParams} from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import {Box, Card, Checkbox, Chip, FormControl, FormControlLabel, FormHelperText, Grid, MenuItem, Paper, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled} from '@mui/material';
import {Field, Formik} from 'formik';
import {ErrorOutline} from '@mui/icons-material';

import NameField from 'src/components/FormFields/firstName';
import {sourceDisplayMap} from 'src/utilities/mapSourceDisplay';
import {editAutomation, setupAutomations} from 'src/api';

import AutomationConditions from './conditionFormFields';
import FormHeader from './formHeader';
import FormFooter from './formFooter';
import LoadingIcon from 'src/components/Loading/loadingIcon';


const SelectWrapper = styled(Select)(
  ({theme}) => `
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
  .MuiTypography-root.placeholder {
    color: ${theme.palette.text.disabled}
}
  `
);

const TableWrapper = styled(TableContainer)(
  ({theme}) => `
    border: 1px solid ${theme.palette.other.outlinedBorder};
    margin-bottom: 20px;
  `
);

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Field is required'),
  description: yup
    .string(),
  category: yup
    .string()
    .required('Field is required'),
  conditions: yup
    .array().of(
      yup.object().shape(
        {
          attribute: yup.string().required(),
          match: yup.string().required()
        }
      )
    )
    .required('Field is required'),
  action: yup
    .string()
    .required('Field is required'),
  configuredFiles: yup
    .array()
    .required('Field is required')
    .min(1),
  agreement: yup
    .boolean()
    .oneOf([true], 'Field is required')
}, ['newEmails', 'preExistingEmails']);

function Loading() {
  return (
    <LoadingIcon contained={true} height="337px" marginTop="200px" />
  );
}

function AutomationForm({type, automation, automationLoading, configsLoading, matchConfigs, setShowErrorAlert, setFormikErrors}) {
  const {carrierId, automationId} = useParams();
  const navigate = useNavigate();
  const [genericError, setGenericError] = useState(false);
  const _categoriesSet = new Set();
  matchConfigs?.map((item) => {
    item?.categories?.forEach((cat) => _categoriesSet.add(parseFloat(cat)));
    return item;
  });
  const categories = sortBy(Array.from(_categoriesSet));


  async function onSubmit(values) {
    window.scroll(0, 0);

    const conditionsMap = {
      'exact': ['exact'],
      'fuzzy': ['fuzzy'],
      'both': ['exact', 'fuzzy']
    };
    const _conditions = values.conditions.map((item) => {
      const newArray = {
        'attribute': item.attribute,
        'match': conditionsMap[item.match]
      };
      return newArray;
    });
    const payload = ({
      'name': values.name,
      'configuredFiles': values.configuredFiles,
      'rule': {
        'category': values.category.toString(),
        'conditions': _conditions,
        'action': values.action
      }
    });

    if (values.description && values.description.length > 0) {
      payload.description = values.description;
    }

    const results = type === 'add' ? await setupAutomations(carrierId, payload) : await editAutomation(carrierId, automationId, payload);

    if (results?.id) {
      navigate(`/${carrierId}/automations?completed=true&name=${values.name}`);
    }

    if (results.error) {
      setGenericError();
    }
  }

  return (
    <Paper>
      <Formik
        initialValues={{
          name: automation?.name || '',
          description: automation?.description || '',
          category: automation?.category || '',
          conditions: automation?.conditions || [{attribute: '', match: ''}],
          action: automation?.action || '',
          configuredFiles: automation?.configuredFiles || null,
          agreement: null
        }}
        enableReinitialize={true}
        validateOnMount={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({isSubmitting, handleSubmit, handleBlur, handleChange, touched, errors, values}) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <FormHeader genericError={genericError}/>
              <hr />
              {isSubmitting ? <Loading /> :
                <Box sx={{p: 2, pb: 1}}>
                  <Grid container>
                    <Grid item xs={4} sx={{pr: 2}}>
                      {automationLoading ?
                        <Skeleton sx={{display: 'inline-block', mb: 2, mr: 1}} width={'100%'} height={50} /> :
                        <NameField name="name" label="Name" onBlur={handleBlur} onChange={handleChange} value={values?.name} errors={touched?.['name'] && (Boolean(errors?.['name']))} helperText={'30 character limit'} errorText={errors?.['name']} maxLength={30} />
                      }
                    </Grid>
                    <Grid item xs={8}>
                      {automationLoading ?
                        <Skeleton sx={{display: 'inline-block'}} width={'100%'} height={50} /> :
                        <NameField name="description" label="Description (Optional)" onBlur={handleBlur} onChange={handleChange} value={values?.description} errors={touched?.['description'] && (Boolean(errors?.['description']))} helperText={'70 character limit'} errorText={errors?.['description']} maxLength={70} />
                      }
                    </Grid>
                    <Grid item xs={12} sx={{mb: 3}}>
                      <hr />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">If ALL of the following are true:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{display: 'flex'}}>
                      <Typography display="inline-block" sx={{mr: 3, mt: '25px'}} variant="body1">Match Category is</Typography>
                      {automationLoading ?
                        <Skeleton sx={{display: 'inline-block', mt: '27px', mb: 2}} width={91} height={20} /> :
                        <FormControl name='category' error={Boolean(touched.category) && Boolean(errors.category)}>
                          <SelectWrapper
                            sx={{width: '160px', display: 'inline-block'}}
                            MenuProps={{
                              disableScrollLock: true
                            }}
                            displayEmpty
                            id="category"
                            name="category"
                            value={values.category}
                            onChange={handleChange}
                          >
                            <MenuItem disabled value=''><Typography className="placeholder" sx={{fontStyle: 'italic'}}>Select</Typography></MenuItem>
                            {categories?.map((cat) => (
                              <MenuItem key={cat} value={cat}>{cat}</MenuItem>
                            ))}
                          </SelectWrapper>
                          {(touched.category && errors.category) && <FormHelperText error>Selection is required</FormHelperText>}
                        </FormControl>
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <AutomationConditions isLoading={automationLoading} values={values} onChange={handleChange} touched={touched} errors={errors} fieldName='conditions' />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">Then perform the following action:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{mb: 3}}>
                      {automationLoading ? <Skeleton sx={{display: 'inline-block', ml: 1, mr: 1}} width={250} height={20} /> :
                        <FormControl error={Boolean(touched.action) && Boolean(errors.action)}>
                          <SelectWrapper
                            sx={{minWidth: '267px', display: 'inline'}}
                            MenuProps={{
                              disableScrollLock: true
                            }}
                            displayEmpty
                            id="action"
                            name="action"
                            value={values.action}
                            onChange={handleChange}
                          >
                            <MenuItem disabled value=''><Typography className="placeholder" sx={{fontStyle: 'italic'}}>Select</Typography></MenuItem>
                            <MenuItem value="accepted">Accept</MenuItem>
                            <MenuItem value="rejected">Reject</MenuItem>
                          </SelectWrapper>
                          {(touched.action && errors.action) && <FormHelperText error>Selection is required</FormHelperText>}
                        </FormControl>
                      }
                      <Typography display="inline-block" sx={{ml: 3, mt: '25px'}} variant="body1">death record match.</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{mb: 3}}>
                      <hr />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl sx={{width: '100%'}} error={Boolean(touched?.configuredFiles) && Boolean(errors?.configuredFiles)}>
                        {(touched?.configuredFiles && errors?.configuredFiles) && <FormHelperText error>Minimum of one required*</FormHelperText>}
                        <TableWrapper sx={{width: '100%'}} className={(touched?.configuredFiles && errors?.configuredFiles) && 'table-error'} component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell colSpan={4}>
                                  <Typography variant="subtitle1" sx={{textTransform: 'none'}}>Execute Automation for Selected File Configurations</Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>File Configuration</TableCell>
                                <TableCell>Sources</TableCell>
                                <TableCell>Match Categories</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(!matchConfigs?.length > 0 || configsLoading) ?
                                <TableRow>
                                  <TableCell sx={{width: '5%'}}>
                                    <Skeleton sx={{display: 'inline-block'}} width={20} height={40} />
                                  </TableCell>
                                  <TableCell sx={{width: '30%'}}>
                                    <Skeleton sx={{display: 'inline-block'}} width={400} height={20} />
                                  </TableCell>
                                  <TableCell sx={{width: '25%'}}>
                                    <Skeleton sx={{display: 'inline-block'}} width={350} height={20} />
                                  </TableCell>
                                  <TableCell sx={{width: '40%'}}>
                                    <Skeleton sx={{display: 'inline-block'}} width={500} height={20} />
                                  </TableCell>
                                </TableRow> :
                                matchConfigs?.map((item) => (
                                  <TableRow key={item.matchFilePrefix}>
                                    <TableCell sx={{width: '5%'}}>
                                      <div key={item.matchFilePrefix}>
                                        <Field as={FormControlLabel} type="checkbox" name='configuredFiles' value={item.matchFilePrefix || ''} control={<Checkbox />} sx={{ml: 1}} /><br />
                                      </div>
                                    </TableCell>
                                    <TableCell sx={{width: '30%'}}>
                                      <Typography variant="body1">{item.nickname}</Typography>
                                      <Typography variant="body2">{item.matchFilePrefix}</Typography>
                                    </TableCell>
                                    <TableCell sx={{width: '25%'}}>
                                      {item?.sources?.map((source, index) => (
                                        <Typography key={source} variant="body2">{sourceDisplayMap[source]}{index !== (item.sources.length - 1) && ','}</Typography>
                                      ))}</TableCell>
                                    <TableCell sx={{width: '40%'}}>{item.categories.map((cat) => (
                                      <Chip size="small" sx={{mr: 1, mb: 1}} key={cat} label={cat} />
                                    ))}</TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableWrapper>
                        {(touched?.configuredFiles && errors?.configuredFiles) && <FormHelperText error><ErrorOutline sx={{display: 'block', float: 'left', fontSize: '18px', mr: '4px', mt: '2px'}} /> *One selection is required</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              }
              <hr />
              <FormFooter isSubmitting={isSubmitting} touched={touched} errors={errors} setShowErrorAlert={setShowErrorAlert} setFormikErrors={setFormikErrors}/>
            </Card>
          </form>
        )}
      </Formik>
    </Paper>
  );
}

AutomationForm.propTypes = {
  type: PropTypes.string.isRequired,
  automation: PropTypes.object,
  automationLoading: PropTypes.bool,
  configsLoading: PropTypes.bool.isRequired,
  matchConfigs: PropTypes.array,
  setShowErrorAlert: PropTypes.func.isRequired,
  setFormikErrors: PropTypes.func.isRequired
};

export default AutomationForm;
