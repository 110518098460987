import PropTypes from 'prop-types';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Grid, Button, Box, styled} from '@mui/material';
import {ArrowBack} from '@mui/icons-material';


const NavBarWrapper = styled(Box)(
  ({theme}) => `
    .nav-bar-container {
        z-index: 999;
        left: 0;
        top: 106px;
        position: fixed;
        align-content: center;
        background-color: ${theme.colors.primary.dark};
        color: ${theme.colors.alpha.white[100]};
    }
  `
);

function BackBar({carrierId, matchJobId, section}) {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  searchParams.delete('updateType'); // updateType is set and managed by the customer record details page so we do not want to carry it over to other pages

  const backLink = `/${carrierId}/all-match-results/${matchJobId}/match-results-file-details?${searchParams.toString()}&openTab=${section}`;

  return (
    <NavBarWrapper>
      <Grid className='nav-bar-container' container height="52px">
        <Grid item xs={12}>
          <Button sx={{color: 'white', fontSize: '14px', fontWeight: '500'}} onClick={() => navigate(backLink)}><ArrowBack sx={{width: '18px', mr: 1}} />BACK TO FILE DETAILS</Button>
        </Grid>
      </Grid>
    </NavBarWrapper>
  );
}

BackBar.propTypes = {
  carrierId: PropTypes.string,
  matchJobId: PropTypes.string,
  section: PropTypes.string
};

export default BackBar;
