import React from 'react';
import PropTypes from 'prop-types';
import {useParams, Link} from 'react-router-dom';
import {Box, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, IconButton, Skeleton} from '@mui/material';
import {Field} from 'formik';
import {CheckCircleOutlineRounded} from '@mui/icons-material';

const TableWrapper = styled(TableContainer)(
  ({theme}) => `
    border: 1px solid ${theme.palette.other.outlinedBorder};
  `
);

const EmptyStateWrapper = styled(Box)(
  ({theme}) => `
    button.Mui-disabled {
      background-color: ${theme.palette.grey[100]};
      padding: 50px;
    }
    #caughtUp {
      color: ${theme.palette.success.main};
    }
  `
);

function DisplayMatchFiles({matchFiles, availableAutomations, tableLoading, touched, setFieldTouched, handleChange, handleBlur, values, errors}) {
  const {carrierId} = useParams();

  const handleAutomationChange = (event) => {
    setFieldTouched('automations');
    handleChange(event);
  };


  return (
    matchFiles?.length === 0 ?
      <EmptyStateWrapper textAlign="center" margin={5}>
        <IconButton disabled><CheckCircleOutlineRounded color="success" sx={{width: '100px', height: '100px'}}></CheckCircleOutlineRounded></IconButton>
        <Typography variant="h4" id="caughtUp" mt={2} mb={1}>All Caught Up</Typography>
        <Typography>Automations for match files are either running or have been completed.</Typography>
      </EmptyStateWrapper> :
      <>
        <Typography variant="subtitle1" mt={4} ml={4} mr={4} mb={2}>Which Match Results file would you like to run automation(s) against?</Typography>
        <FormControl sx={{ml: 4, pr: 8, width: '100%'}} error={touched?.configuredFiles && errors?.configuredFiles}>
          <InputLabel id="match-file-select-helper-label">Match Results File</InputLabel>
          <Select
            label="Match Results File"
            id="matchFile"
            name="matchFile"
            value={values?.matchFile}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.matchFile && errors?.matchFile}
            helperText={touched?.matchFile && errors?.matchFile ? errors?.matchFile : ''}
          >
            {matchFiles?.map((file) => {
              return <MenuItem key={file?.id} value={file?.id}>{file?.fileName}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <Typography variant="body2" ml={4} mr={4} mt={2}>Only files in a 'Pending Review' or 'Review in Progress' status are available to choose from.</Typography>
        <FormControl sx={{p: 4, width: '100%'}}>
          {(touched?.automations && errors?.automations && availableAutomations?.length !== 0 && values?.matchFile) && <FormHelperText error>Minimum of one required.*</FormHelperText>}
          <TableWrapper sx={{width: '100%'}} component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography variant="subtitle1" sx={{textTransform: 'none'}}>Available Automations to Run{availableAutomations?.length !== 0 && '*'}</Typography>
                  </TableCell>
                </TableRow>
                {(tableLoading || availableAutomations?.length > 0) &&
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Automation Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Last Updated Date</TableCell>
              </TableRow>
                }
              </TableHead>
              <TableBody>
                {tableLoading &&
                <TableRow>
                  <TableCell>
                    <Skeleton variant="rectangle" sx={{width: '20px'}} />
                  </TableCell>
                  <TableCell sx={{width: '25%'}}>
                    <Skeleton variant="rectangle" sx={{width: '95%'}} />
                  </TableCell>
                  <TableCell sx={{width: '50%'}}>
                    <Skeleton variant="rectangle" sx={{width: '95%'}} />
                  </TableCell>
                  <TableCell sx={{width: '25%'}}>
                    <Skeleton variant="rectangle" sx={{width: '95%'}} />
                  </TableCell>
                </TableRow>
                }
                {
                  availableAutomations?.map((item) => (
                    <TableRow key={item.name}>
                      <TableCell>
                        <Field
                          as={FormControlLabel}
                          type="checkbox"
                          name='automations'
                          id={item.id}
                          value={item.id}
                          onChange={handleAutomationChange}
                          control={<Checkbox />}
                          sx={{mr: -1}} />
                      </TableCell>
                      <TableCell sx={{width: '25%'}}>
                        <Typography variant="body1">{item.name}</Typography>
                      </TableCell>
                      <TableCell sx={{width: '50%'}}>
                        <Typography variant="body1">{item.description}</Typography>
                      </TableCell>
                      <TableCell sx={{width: '25%'}}>
                        <Typography variant="body1">{item.updated}</Typography>
                      </TableCell>
                    </TableRow>
                  ))
                }
                {availableAutomations?.length === 0 && !tableLoading &&
              <TableRow>
                <TableCell sx={{width: '50%'}}>
                  <Typography margin={2}>The match file selected has no automations assigned. Go to the <Link to={`/${carrierId}/automations`} sx={{cursor: 'pointer'}} underline="always">All Automations</Link> page to add an automation.</Typography>
                </TableCell>
              </TableRow>
                }

                {!availableAutomations && !tableLoading &&
              <TableRow>
                <TableCell sx={{width: '50%'}}>
                  <Typography margin={2}>Automations will display here after a Match Results file is selected above.</Typography>
                </TableCell>
              </TableRow>
                }
              </TableBody>
            </Table>
          </TableWrapper>
          {availableAutomations?.length !== 0 && !(touched?.automations && errors?.automations && values?.matchFile) &&
          <Typography variant="body2" paddingTop={1}>*At least one automation must be selected to run an automation.</Typography>
          }
          {availableAutomations?.length !== 0 && touched?.automations && errors?.automations && values?.matchFile &&
          <FormHelperText sx={{mt: 1}} error>*One selection is required.</FormHelperText>
          }
        </FormControl>
      </>
  );
}

DisplayMatchFiles.propTypes = {
  matchFiles: PropTypes.array.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  availableAutomations: PropTypes.array,
  tableLoading: PropTypes.bool.isRequired
};

export default DisplayMatchFiles;
