import {sortBy} from 'lodash';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';

import MatchAccordion from './matchAccordion';


function AllMatchAccordions({matches, customerRecord, refetchData, isVerified, fileStatus}) {
  const majorCategoriesSet = new Set();

  matches?.forEach((match) => {
    const topCat = match.category.split('.');
    majorCategoriesSet.add(parseInt(topCat[0]));
  });

  const majorCategories = sortBy(Array.from(majorCategoriesSet));

  return (majorCategories.map((cat) =>
    <Box key={cat}>
      <Box key={cat} className="accordion-header" sx={{mt: 3}}>Match Category {cat}</Box>
      {matches.filter((match) => {
        const categoryGroup = match.category.split('.');
        const categoryGroupNumber = categoryGroup[0];

        return categoryGroupNumber === cat.toString();
      }).map((match) =>
        <MatchAccordion
          refetchData={refetchData}
          isVerified={isVerified}
          key={match?.emId}
          matchData={match}
          customerData={customerRecord}
          fileStatus={fileStatus}
        />
      )}
    </Box>
  ));
}

AllMatchAccordions.propTypes = {
  matches: PropTypes.array,
  customerRecord: PropTypes.object,
  refetchData: PropTypes.func,
  isVerified: PropTypes.bool,
  fileStatus: PropTypes.string
};

export default AllMatchAccordions;
