import React from 'react';
import {Outlet, useParams, useOutletContext} from 'react-router-dom';
import {useQuery} from 'react-query';

import {getMatchJobDetails} from 'src/api';


function MatchJobContext() {
  const {carrierId, matchJobId} = useParams();
  const {carrier} = useOutletContext();

  const {data} = useQuery(['getMatchJobDetails', carrierId, matchJobId], () => getMatchJobDetails(carrierId, matchJobId), {placeholderData: {}});

  return (<Outlet context={{carrier, matchJob: data}}/>);
}

export default MatchJobContext;
