
import PropTypes from 'prop-types';
import {filter} from 'lodash';
import {Avatar, Box, Grid, Tooltip, Typography, styled} from '@mui/material';
import {SmartToy} from '@mui/icons-material';

import MatchDecisionChip from 'src/components/MatchDecisionChip';
import {getReviewerName} from 'src/utilities/getReviewerName';


const AvatarWrapper = styled(Box)(
  ({theme}) => `
    .bot-avatar {
      background-color: ${theme.palette.background.default};
      border: 1px solid ${theme.palette.action.disabled};
    }
  `
);

const DisplayBotAvatar = ({botReviewers}) => {
  if (botReviewers?.length === 0) {
    return;
  }
  const botNames = botReviewers.map((reviewer) => reviewer.name).join(',\n');
  return (
    <Tooltip arrow placement="left" title={<div style={{whiteSpace: 'pre-line'}}>{botNames}</div>}>
      <Avatar className='bot-avatar' sx={{display: 'inline-block', textAlign: 'center', pt: '10px', fontSize: '20px', mt: 1, ml: '3px'}}>
        <SmartToy color='disabled' sx={{fontSize: '20px'}}/>
      </Avatar>
    </Tooltip>
  );
};

DisplayBotAvatar.propTypes = {
  botReviewers: PropTypes.array
};

const DisplayAvatars = ({reviewer}) => {
  if (reviewer === 'N/A') {
    return reviewer;
  }
  if (reviewer?.type === 'bot') {
    return;
  }
  const firstName = reviewer?.firstName;
  const lastName = reviewer?.lastName;
  const firstInit = firstName?.charAt(0);
  const lastInit = lastName?.charAt(0);
  return (
    <Tooltip key={reviewer?.email} arrow placement="left" title={getReviewerName(reviewer)}><Avatar sx={{display: 'inline-block', textTransform: 'uppercase', textAlign: 'center', pt: '10px', fontSize: '20px', mt: 1, ml: '3px'}}>{firstInit}{lastInit}</Avatar></Tooltip>
  );
};

DisplayAvatars.propTypes = {
  reviewer: PropTypes.shape({
    type: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string
  })
};

function StatusBar({matchId, updateType, matchDecision, reviewers}) {
  const isMatchDecisionVisible = !(matchId || updateType === 'update');

  return (
    <Grid container my={3}>
      <Grid item xs={6}>
        {isMatchDecisionVisible &&
          <Typography variant="body1">Customer Record Match Decision: <MatchDecisionChip matchDecision={matchDecision} sx={{ml: 1}}/></Typography>
        }
      </Grid>
      <Grid item xs={6} sx={{textAlign: 'right', marginTop: -2, visibility: reviewers?.length > 0 ? 'visible' : 'hidden'}}>
        <Typography variant="body1" sx={{display: 'inline-flex'}}>Reviewers:</Typography>
        <AvatarWrapper sx={{display: 'inline-flex', ml: 1}}>
          <DisplayBotAvatar botReviewers={(filter(reviewers, ['type', 'bot']))} />
          {reviewers?.map((reviewer) => (
            <DisplayAvatars key={reviewer.email} reviewer={reviewer} />
          ))}
        </AvatarWrapper>
      </Grid>
    </Grid>
  );
}

StatusBar.propTypes = {
  matchId: PropTypes.string,
  updateType: PropTypes.string,
  matchDecision: PropTypes.string,
  reviewers: PropTypes.array
};

export default StatusBar;
