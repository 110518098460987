import React from 'react';
import {Typography, Grid, Button} from '@mui/material';
import {ReactComponent as EvadataLogo} from 'src/images/EvadataLogo404.svg';
import CommGraphic from 'src/images/500ServerCommGraphic.png';
import {RefreshRounded} from '@mui/icons-material';

function ErrorPage500() {
  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{textAlign: 'center', marginTop: '100px'}}>
        <EvadataLogo />
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'center', marginTop: '20px'}}>
        <img src={CommGraphic} alt="Page error 500" />
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'center', marginTop: '10px'}}>
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'center', marginTop: '50px'}}>
        <Typography variant="h5" color="black" sx={{maxWidth: '700px', margin: '0 auto'}}>The server encountered an internal error and was not able to complete your request. Please try refreshing your browser.</Typography>
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'center', marginTop: '50px'}}>
        <Button onClick={refreshPage} variant="contained" color="primary" startIcon={<RefreshRounded />}>Refresh</Button>
      </Grid>
    </Grid>
  );
}


ErrorPage500.propTypes = {};

export default ErrorPage500;
