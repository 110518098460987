import React from 'react';
import {Outlet, useParams} from 'react-router-dom';
import {useQuery} from 'react-query';

import {getCarrierInfo} from 'src/api';


function CarrierContext() {
  const {carrierId} = useParams();
  const {data} = useQuery(['getCarrierInfo', carrierId], () => getCarrierInfo(carrierId), {initialData: {}});

  const carrier = {
    id: data?.id,
    name: data?.name,
    tiers: data?.tiers,
    isVerified: data?.tiers?.includes('lens-verified') || false
  };

  return (<Outlet context={{carrier}}/>);
}

export default CarrierContext;
