import React from 'react';
import {Box, Button, Chip, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {sourceDisplayMap} from 'src/utilities/mapSourceDisplay';
import MatchDecisionChip from 'src/components/MatchDecisionChip';


const operationMap = {
  'new': 'Net New',
  'update': 'Updated',
  'delete': 'Deleted'
};

const operationLinkMap = {
  'new': 'new',
  'update': 'updated',
  'delete': 'deleted'
};

const columns = function(carrierId) {
  return (
    [
      {
        field: 'customerId', headerName: 'Customer ID', sortable: false, flex: 1.5, renderCell: (params) => {
          return (
            <div className="wrap-cell customer-id flex">
              <Typography variant="body2">{params.value}</Typography></div>
          );
        }
      },
      {
        field: 'emId', headerName: 'Match ID', sortable: false, flex: 1.4, renderCell: (params) => {
          return (
            <div className="wrap-cell customer-id flex">
              <Typography variant="body2">{params.value}</Typography></div>
          );
        }
      },
      {
        field: 'matchDate', headerName: 'Match Date', sortingOrder: ['desc', 'asc'], flex: 1, renderCell: (params) => {
          return (
            <div className="wrap-cell customer-id flex">
              <Typography variant="body2">{params.value}</Typography></div>
          );
        }
      },
      {
        field: 'operation', headerName: 'Match Classification', sortable: false, flex: 1, renderCell: (params) => {
          return (
            <div className="wrap-cell customer-id flex">
              <Typography variant="body2">{operationMap[params.value]}</Typography></div>
          );
        }
      },
      {
        field: 'source', headerName: 'Source Match', sortable: false, flex: 1, renderCell: (params) => {
          return (
            <Box className="wrap-cell customer-id flex">
              <Typography variant="body2">{sourceDisplayMap[params.value]}</Typography>
            </Box>
          );
        }
      },
      {
        field: 'category', headerName: 'Category Match', sortable: false, flex: 1, renderCell: (params) => {
          const _categories = params?.value?.split(',');
          return (
            <Box className="wrap-cell customer-id flex" sx={{py: 1, minHeight: '100px', display: 'flex !important', alignItems: 'center', flexWrap: 'wrap'}}>
              <Typography variant="body2">
                {_categories?.map((cat) => (
                  <Chip key={cat} sx={{mr: '3px', mb: '3px'}} label={cat} />
                ))}
              </Typography>
            </Box>
          );
        }
      },
      {
        field: 'matchDecision', headerName: 'Match ID Decision', sortable: false, flex: 1, renderCell: (params) => {
          let matchDecision;
          if (!params.value) {
            matchDecision = '-';
          } else {
            matchDecision = <MatchDecisionChip variant='lookup' matchDecision={params.value}/>;
          }

          return (
            <Box sx={{textTransform: 'capitalize'}} className="wrap-cell customer-id flex">
              <Typography variant="body2">{matchDecision}</Typography>
            </Box>
          );
        }
      },
      {
        field: 'action',
        headerName: 'Action',
        flex: 1.5,
        sortable: false,
        cellClassName: 'cat-level action-col',
        renderCell: (params) => {
          return (
            <Box className="wrap-cell customer-id flex">
              <Typography variant="body2">
                <Button variant="outlined" size="small" id="1" LinkComponent={Link} to={`/${carrierId}/all-match-results/${params.row.matchJobId}/match-results-file-details/${operationLinkMap[params.row.operation]}/${params.row.customerId}/customer-record-details?matchId=${params?.row?.emId}`} sx={{minWidth: '150px'}}>
              View Details
                </Button>
              </Typography>
            </Box>
          );
        }
      }
    ]);
};

export default columns;
