import React from 'react';
import {Grid} from '@mui/material';

import {SidebarProvider} from './contexts/SidebarContext';
import CarrierAppBar from './CarrierAppBar';
import Sidebar from './Sidebar';
import BaseLayout from '../baseLayout';
import {Outlet, useOutletContext} from 'react-router-dom';


function AppBar() {
  return (
    <>
      <CarrierAppBar/>
      <Grid item xl={12} sx={{padding: '0px !important'}}>
        <SidebarProvider>
          <Sidebar/>
        </SidebarProvider>
      </Grid>
    </>
  );
}

function CarrierLayout() {
  return (
    <BaseLayout appBar={<AppBar/>}>
      <Outlet context={useOutletContext()}/>
    </BaseLayout>
  );
}

export default CarrierLayout;
