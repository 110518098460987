import React from 'react';
import PropTypes from 'prop-types';

import {styled, Box, Alert, Button} from '@mui/material';

const AlertWrapper = styled(Box)(
  ({theme}) => `
    position: absolute;
    top: 75px;
    z-index: 1000;
    width: 100%;
    left: 0px;
    .MuiPaper-root {
      position: relative;
      box-shadow: 0px 2px 4px ${theme.palette.text.primary}99;
      width: 300px;
      margin: 0 auto;
    }
    .MuiButtonBase-root {
      position: absolute;
      right: 0px;
      top: 5px;
      font-weight: 500;
      text-transform: capitalize;
      text-decoration: underline;
    }
    @media screen and (max-width: 1279px) {
      left: 0px;
      width: 100%;
    }
  `
);

function ErrorAlert({formik, showErrorAlert}) {
  const errors = formik?.errors ? Object.keys(formik.errors) : {};
  const errorCount = formik?.errors ? errors.length : 0;

  const scrollErrorIntoView = () => {
    if (errorCount > 0) {
      document.getElementsByName(errors[0])[0]?.focus();
    }
  };

  return (
    ((errorCount > 0) && showErrorAlert) && <AlertWrapper>
      <Alert
        action={
          <Button onClick={scrollErrorIntoView} variant="text" color="error">View</Button>
        } severity="error">
        {errorCount} Error(s) Found</Alert>
    </AlertWrapper>
  );
}

ErrorAlert.propTypes = {
  formik: PropTypes.object,
  showErrorAlert: PropTypes.bool
};

export default ErrorAlert;
