import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {Grid, styled} from '@mui/material';

import BaseAppBar from '../../AppBar';
import CarrierName from '../../AppBar/CarrierName';
import HeaderUserbox from '../../AppBar/Userbox';
import EvadataLogo from '../../AppBar/EvadataLogo';


const LogoContainerStyled = styled(Grid)(({theme}) => (`
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%);
  z-index: 1100;
  position: fixed;
  background-color: ${theme.sidebar.background};
  height: 80px;
`));

function CarrierAppBar() {
  const {carrierId} = useParams();

  return (
    <BaseAppBar>
      <LogoContainerStyled container spacing={2} sx={{pl: 2, pr: 2}}>
        <Grid item xs={6} sm={6} sx={{pt: 3}}>
          <Link to={`/${carrierId}/all-match-results`}><EvadataLogo sx={{ml: 12}}/></Link>
        </Grid>
        <Grid item xs={4} sm={5} sx={{textAlign: 'right', alignContent: 'center'}}>
          <Link to='/'><CarrierName/></Link>
        </Grid>
        <Grid item xs={2} sm={1} sx={{textAlign: 'right', mt: '6px'}}>
          <HeaderUserbox />
        </Grid>
      </LogoContainerStyled>
    </BaseAppBar>
  );
}

export default CarrierAppBar;
