import React, {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, Skeleton} from '@mui/material';


const countSkeleton = <Skeleton variant='rectangular' width={25} height={22} sx={{mx: '4px'}} />;

function SectionButtonGroup({netNewRecordsCount, updatedRecordsCount, deletedRecordsCount}) {
  const [searchParams, setSearchParams] = useSearchParams({section: 'new'});
  const selectedSection = searchParams.get('section');

  const handleChangeSection = function(section) {
    searchParams.set('section', section);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (selectedSection === 'new') {
      // set the default section in the url query so that parent components are aware
      // Long explanation: The URL does not have the "section" query param set
      // We have a default initiation in the "useSearchParams" hook, which sets it based on if the
      // carrier is verified or not.
      // Now this queryParam needs to be available in the url query param so that other components
      // can display the appropriate view in their components.
      // Remember: URL query params are the way we manage state
      // The below call sets "section" in url. We need this to run only once when the
      // component renders. After that the user may toggle the value.
      // Also, note that the default initiation in useSearchParams is only take into account if there
      // isn't a value for "section" in the query params.
      setSearchParams(searchParams);
    } // eslint-disable-next-line
  }, []); 

  return (
    <ButtonGroup sx={{mb: 2}}>
      <Button sx={{textTransform: 'none', fontWeight: 400}} onClick={() => handleChangeSection('new')} variant={selectedSection === 'new' ? 'contained' : 'outlined'}><strong>NET NEW</strong>&nbsp;Matches ({netNewRecordsCount === null ? countSkeleton : netNewRecordsCount})</Button>
      <Button sx={{textTransform: 'none', fontWeight: 400}} onClick={() => handleChangeSection('updated')} variant={selectedSection === 'updated' ? 'contained' : 'outlined'}><strong>UPDATED</strong>&nbsp;Matches ({updatedRecordsCount === null ? countSkeleton : updatedRecordsCount})</Button>
      <Button sx={{textTransform: 'none', fontWeight: 400}} onClick={() => handleChangeSection('deleted')} variant={selectedSection === 'deleted' ? 'contained' : 'outlined'}><strong>DELETED</strong>&nbsp;Matches ({deletedRecordsCount === null ? countSkeleton : deletedRecordsCount})</Button>
    </ButtonGroup>
  );
}

SectionButtonGroup.propTypes = {
  netNewRecordsCount: PropTypes.number,
  updatedRecordsCount: PropTypes.number,
  deletedRecordsCount: PropTypes.number
};

export default SectionButtonGroup;
